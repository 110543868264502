<div class="bg-white flex flex-col mr-4 flex-grow min-h-0">
  <div class="px-6 py-3 flex items-center w-full justify-between">
    <div>
      <app-static-asset
        [alt]="'zimmerpflanzen.de'"
        [extension]="'png'"
        [source]="'assets/static/logo_combined_horizontal_h60'"
        class="h-6"
      ></app-static-asset>
    </div>
    <button aria-label="Menu schliessen" class="text-4xl" (click)="close()">
      <fa-icon [icon]="closeIcon"></fa-icon>
    </button>
  </div>

  <div class="flex-grow flex-col min-h-0 overflow-y-auto">
    <app-section-items>
      <app-section-header>Shop</app-section-header>
      <app-section-item (click)="close()"
        ><a class="link" [routerLink]="'/'">Startseite</a></app-section-item
      >
      <app-section-item (click)="close()"
        ><a
          class="link"
          [routerLink]="'/'"
          [fragment]="'sonderangebote'"
          title="Sonderangebote Zimmerpflanzen"
          >Sonderangebote</a
        ></app-section-item
      >
      @for (
        categoryOption of category.mainCategories();
        track categoryOption.id
      ) {
        <app-section-item (click)="close()"
          ><a class="link" [routerLink]="categoryOption.url">{{
            categoryOption.name
          }}</a></app-section-item
        >
      }
    </app-section-items>

    <app-section-items>
      <app-section-header>Informationen</app-section-header>
      <app-section-item
        ><a
          class="link"
          [routerLink]="['./Informationen/Ueber-uns']"
          (click)="close()"
          >Über uns</a
        ></app-section-item
      >
      <app-section-item
        ><a class="link" href="/blog" (click)="close()"
          >Unser Blog</a
        ></app-section-item
      >
      <app-section-item
        ><a
          class="link"
          [routerLink]="['./Informationen/Versand']"
          (click)="close()"
          >Versandinformationen</a
        ></app-section-item
      >
      <app-section-item
        ><a
          class="link"
          [routerLink]="['./Informationen/Zahlungsmethoden']"
          (click)="close()"
          >Zahlungsmethoden</a
        ></app-section-item
      >
      <app-section-item
        ><a
          class="link"
          [routerLink]="['./Informationen/Schadensmeldung']"
          (click)="close()"
          >Schadensmeldung</a
        ></app-section-item
      >
    </app-section-items>

    <app-section-items>
      <app-section-header>Konto</app-section-header>
      @if (customerState.isLoggedIn()) {
        <app-section-item
          ><a
            class="link"
            (click)="close()"
            [routerLink]="['./account/overview']"
            >Bestellübersicht</a
          ></app-section-item
        >
        <app-section-item
          ><a
            class="link"
            (click)="close()"
            [routerLink]="['./account/wishlist']"
            >Wunschliste</a
          ></app-section-item
        >
      } @else {
        <app-section-item
          ><a class="link" (click)="close()" [routerLink]="['./account/login']"
            >Konto anmelden</a
          ></app-section-item
        >
        <app-section-item
          ><a
            class="link"
            (click)="close()"
            [routerLink]="['./account/register']"
            >Konto erstellen</a
          ></app-section-item
        >
      }
    </app-section-items>

    <app-section-items>
      <app-section-header>Allgemein</app-section-header>
      <app-section-item
        ><a
          class="link"
          (click)="close()"
          [routerLink]="['./Informationen/AGB']"
          >AGB</a
        ></app-section-item
      >

      <app-section-item
        ><a
          class="link"
          (click)="close()"
          [routerLink]="['./Informationen/Wiederrufsrecht']"
          >Wiederrufsrecht</a
        ></app-section-item
      >
      <app-section-item
        ><a
          class="link"
          (click)="close()"
          [routerLink]="['./Informationen/Datenschutz']"
          >Datenschutz</a
        ></app-section-item
      >
      <app-section-item
        ><a
          class="link"
          (click)="close()"
          [routerLink]="['./Informationen/Impressum']"
          >Impressum</a
        ></app-section-item
      >
    </app-section-items>
  </div>
</div>
