<div class="flex items-center">
  <div class="flex-grow min-w-0">
    <ng-content select="app-collapsable-header"></ng-content>
  </div>
  <button class="px-2" (click)="toggle()">
    <div  [ngClass]="{'rotate-90': open()}" class="transition-all">
      <fa-icon [icon]="faCaretRight"></fa-icon>
    </div>
  </button>
</div>
<div [style.max-height]="open() ? '100%' : 0" class="overflow-hidden">
  <ng-content select="app-collapsable-body"></ng-content>
</div>
