<app-section-items>
  <app-section-header>Produkte</app-section-header>

  @for (item of cartState().lineItems; track $index) {
    <app-section-item>
      <div
        class="h-full px-2 py-2 relative rounded-b-md flex flex-grow min-w-0"
      >
        <a
          [routerLink]="'/product/' + item.payload.productNumber"
          [queryParams]="{ backlink: '/checkout/cart' }"
          class="w-16 mx-2 flex items-center justify-center flex-shrink-0"
        >
          @if (item.cover; as cover) {
            <app-media-image
              class="h-full"
              [size]="'small'"
              [objectFit]="'object-contain'"
              [image]="cover"
              [alt]="item.label ?? ''"
            ></app-media-image>
          } @else if (item.type === "promotion") {
            <div
              class="rounded-full text-primary-orange flex items-center justify-center"
            >
              <fa-icon [icon]="promotionIcon" class="text-2xl"></fa-icon>
            </div>
          }
        </a>

        <div class="space-y-1">
          @if (item.type === "promotion") {
            <div class="text-xs text-gray-600">Promotionscode</div>
          }
          <h2 class="line-clamp-2 flex-grow min-h-0">
            {{ item.label }}
          </h2>
          <div>
            <!--                <app-property [product]="item.payload"></app-property>-->
          </div>
          @if (item.type === "product") {
            <div class="flex gap-x-2">
              <app-stepper
                [value]="item.quantity"
                (valueChange)="updateQuantity(item, $event)"
              ></app-stepper>

              @if (item.price) {
                <app-price-badge>
                  {{ item.price.totalPrice | currency }}
                </app-price-badge>
              }
            </div>
          } @else if (item.type === "promotion") {
            <div class="flex gap-x-2">
              <app-button-primary
                [color]="'orange'"
                [size]="'small'"
                [action]="removeCoupon(item)"
                >Entfernen</app-button-primary
              >
              @if (item.price) {
                <app-price-badge>
                  {{ item.price.totalPrice | currency }}
                </app-price-badge>
              }
            </div>
          }
        </div>
      </div>

      <div></div>
    </app-section-item>
  }
</app-section-items>
