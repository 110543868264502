@if (groupedProperties().length > 0) {
  <h2 class="text-2xl my-8 underline decoration-primary">Faktenblatt</h2>

  <div
    class="grid grid-cols-2 md:grid-cols-4 border-l md:border-l-none border-gray-200 border-t rounded-md"
  >
    @for (property of groupedProperties(); track property.id) {
      <div class="bg-gray-100 px-6 border-b py-1 border-gray-200">
        {{ property.name }}
      </div>
      <div class="px-6 border-b border-r py-1 border-gray-200">
        {{ property.value }}
      </div>
    }
  </div>
}
