<app-header header="Zahlungsmethoden"></app-header>

<!--@defer (on viewport) {-->
<!--  <img src="assets/zahlungsarten.png" alt="zahlungsarten" class="h-64 object-contain" />-->
<!--}  @placeholder {-->
<!--  <div class="h-full w-full"></div>-->
<!--}-->

<app-container>
  <div class="prose py-8">
    <h2>Bezahlen Sie sicher und bequem</h2>
    <p>
      Wir bieten Ihnen verschiedene Zahlungsmöglichkeiten an, damit Sie Ihre
      Bestellung so einfach wie möglich abschließen können. Wählen Sie einfach
      die für Sie passende Zahlungsart aus.
    </p>

    @if (paymentMethods(); as paymentState) {
      @if (!paymentState.loading) {
        <div
          class="-mx-6 grid grid-cols-2 gap-1 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-3"
        >
          @for (payment of paymentState.elements; track payment.id) {
            <div class="bg-white p-8 sm:p-10 flex flex-col items-center">
              <!--            <span class="text-center">{{payment.name}}</span>-->
              @if (payment.media?.url; as url) {
                <img
                  class="max-h-12 w-full object-contain"
                  [ngSrc]="url"
                  [alt]="payment.name"
                  width="158"
                  height="48"
                />
              }
            </div>
          }
        </div>
      }
    }
  </div>
</app-container>
