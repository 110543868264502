<app-header [header]="'Wiederrufsrecht'"></app-header>

<app-container>
  <div class="prose py-8">
    <h2>Wiederrufsrecht</h2>

    <h3>Geltungsbereich</h3>

    <p>
      Verbraucher haben das Recht, gemäß den folgenden Bedingungen einen
      Rücktritt zu erklären. Als Verbraucher gilt jede natürliche Person, die
      ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend nicht ihrer
      gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet werden
      können.
    </p>

    <h3>Widerrufsrecht</h3>
    <p>
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
      Vertrag zu widerrufen. Die Frist dafür beträgt vierzehn Tage ab dem Tag,
      an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
      Transporteur ist, die letzte Ware in Besitz genommen haben.
    </p>

    <p>
      Um Ihr Rücktrittsrecht auszuüben, müssen Sie uns, Tropico Trading GmbH ,
      Industriestraße 10, 6343 Rotkreuz, Schweiz, per E-Mail an:
      <a href="mailto:info@zimmerpflanzen.de">{{ "info@zimmerpflanzen.de" }}</a
      >, mit einer klaren Erklärung über Ihren Entschluss, diesen Vertrag zu
      widerrufen, informieren.
    </p>

    <p>
      Zur Wahrung der Rücktrittsfrist genügt es, dass Sie die Mitteilung über
      die Ausübung des Widerrufsrecht vor Ablauf der Frist absenden.
    </p>

    <h3>Folgen des Widerrufsrechts</h3>
    <p>
      Wenn Sie diesen Vertrag widerrufen, erstatten wir Ihnen alle Zahlungen,
      die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (außer
      zusätzlichen Kosten, die entstehen, wenn Sie eine andere Art der Lieferung
      als die von uns angebotene, günstigste Standardlieferung wählen),
      unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag, an dem die
      Mitteilung über Ihren Rücktritt bei uns eingegangen ist. Für die
      Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
      ursprünglichen Transaktion verwendet haben, es sei denn, es wurde
      ausdrücklich etwas anderes vereinbart; in keinem Fall berechnen wir Ihnen
      Entgelte für die Rückzahlung.
    </p>

    <p>
      Wir können die Rückzahlung verweigern, bis wir die Waren zurückerhalten
      haben oder Sie den Nachweis erbracht haben, dass Sie die Waren
      zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
    </p>

    <p>
      Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
      vierzehn Tagen ab dem Tag, an dem Sie uns über den Rücktritt dieses
      Vertrags informieren, an uns zurückzusenden oder zu übergeben. Die Frist
      ist gewahrt, wenn Sie die Waren vor Ablauf der vierzehntägigen Frist
      absenden. Sie tragen die unmittelbaren Kosten der Rücksendung von
      paketversandfähigen Waren.
    </p>

    <p>
      Für einen Wertverlust der Waren müssen Sie nur aufkommen, wenn dieser
      Wertverlust auf einen zur Überprüfung der Beschaffenheit, Eigenschaften
      und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen
      zurückzuführen ist.
    </p>

    <h3>Ausschluss des Widerrufsrechts</h3>
    <p>
      Das Widerrufsrechts besteht nicht für Verträge zur Lieferung von Waren,
      die schnell verderben können oder deren Verfallsdatum schnell
      überschritten würde.
    </p>
  </div>
</app-container>
