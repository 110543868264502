import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { NestedSectionComponent } from '../../components/nested-section/nested-section.component';
import { SectionHeaderComponent } from '../../components/section-header/section-header.component';
import { SectionItemsComponent } from '../../components/section-items/section-items.component';
import { Schemas } from '../../../api-types/storeApiTypes';
import { SectionItemValuesComponent } from '../../components/section-item-values/section-item-values.component';
import { SectionItemComponent } from '../../components/section-item/section-item.component';

@Component({
    selector: 'app-cart-total',
    imports: [
        CurrencyPipe,
        NestedSectionComponent,
        SectionHeaderComponent,
        SectionItemsComponent,
        SectionItemValuesComponent,
        PercentPipe,
        SectionItemComponent,
    ],
    templateUrl: './cart-total.component.html',
    styleUrl: './cart-total.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartTotalComponent {
  cartState = input.required<Schemas['Cart']>();
}
