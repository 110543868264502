import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ContainerComponent } from '../components/container/container.component';

@Component({
    selector: 'app-teaser-text',
    imports: [ContainerComponent],
    templateUrl: './teaser-text.component.html',
    styleUrl: './teaser-text.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeaserTextComponent {
  teaser = input.required<string>();
  heading = input.required<string>();
  text = input.required<string>();
}
