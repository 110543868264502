import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { StoreApiService } from '../../store-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from '../../components/header/header.component';
import { OrderStateService } from '../order-state.service';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';
import { CurrencyPipe, NgIf } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { ContainerComponent } from '../../components/container/container.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { PaymentOptionsComponent } from '../../components/payment-options/payment-options.component';

@Component({
    selector: 'app-order-error',
    imports: [
        HeaderComponent,
        ContainerComponent,
        SpinnerComponent,
        ButtonPrimaryComponent,
        NgIf,
        PaymentOptionsComponent,
        CurrencyPipe,
    ],
    templateUrl: './order-error.component.html',
    styleUrl: './order-error.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderErrorComponent {
  orderState = inject(OrderStateService);
  storeApi = inject(StoreApiService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  fb = inject(FormBuilder);
  paymentOptions = viewChild.required(PaymentOptionsComponent);
  errorMessage = signal<string | null>(null);

  order = this.orderState.detailStore.state;

  form = this.fb.group({
    paymentMethodId: this.fb.control('', [Validators.required]),
  });

  retryPayment = async () => {
    if (!this.form.valid) {
      this.errorMessage.set('Lieder sind nicht alle Angaben vollständig');
      return;
    }

    this.errorMessage.set(null);
    const paymentMethodId = this.form.getRawValue().paymentMethodId;
    if (!paymentMethodId) {
      return;
    }

    const orderId = this.route.snapshot.params['orderId'];

    if (!orderId) {
      return;
    }

    await this.storeApi.apiClient.invoke(
      'orderSetPayment post /order/payment',
      {
        body: { paymentMethodId, orderId },
      },
    );

    await this.paymentOptions().handlePayment();

    const result = await this.storeApi.apiClient.invoke(
      'handlePaymentMethod post /handle-payment',
      {
        body: {
          orderId: orderId,
          finishUrl: `/checkout/${orderId}/success`,
          errorUrl: `/checkout/${orderId}/error`,
        },
      },
    );

    if (result && result.data.redirectUrl) {
      window.location.href = result.data.redirectUrl;
    } else {
      this.errorMessage.set(
        'Leider konnte die Zahlung nicht verarbeitet werden. Versuchen Sie es später oder wählen Sie eine andere Zahlungsmittel.',
      );
    }
  };
}
