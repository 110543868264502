<div class="aspect-square mt-8 max-h-96 overflow-hidden">
  <app-static-asset
    class="h-full w-full rounded-full"
    [alt]="header()"
    [source]="image()"
    [extension]="'jpeg'"
  ></app-static-asset>
</div>

<div class="relative mx-auto max-w-7xl py-12">
  <div class="">
    <h2
      class="mt-2 text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl"
    >
      {{ header() }}
    </h2>
    <p class="mt-6 text-base leading-7 text-gray-600">
      <ng-content></ng-content>
    </p>
  </div>
</div>
