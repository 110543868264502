import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  output,
} from '@angular/core';
import { Category, CategoryService } from '../../category.service';
import { CollapsableOptionGroupComponent } from '../../components/collapsable-option-group/collapsable-option-group.component';
import { CollapsableOptionComponent } from '../../components/collapsable-option/collapsable-option.component';
import { CollapsableOptionHeaderComponent } from '../../components/collapsable-option-header/collapsable-option-header.component';
import { MetaService } from '../../meta.service';

@Component({
  selector: 'app-product-categories',
  imports: [
    CollapsableOptionGroupComponent,
    CollapsableOptionComponent,
    CollapsableOptionHeaderComponent,
  ],
  templateUrl: './product-categories.component.html',
  styleUrl: './product-categories.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCategoriesComponent {
  category = inject(CategoryService);
  meta = inject(MetaService);

  open = model<boolean>(false);
  collapsable = input<boolean>(false);
  selected = output<Category>();
  showLabel = input<boolean>(false);

  selectedCategories = computed(() => {
    return [this.category.current()?.name ?? ''];
  });
}
