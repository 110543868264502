<app-header [header]="'Schadensmeldung'"></app-header>

<app-container>
  <div class="prose py-8">
    <p>
      Bei schwerwiegenden Beschädigungen melden Sie uns den Schaden so schnell
      wie möglich unter unserer E-Mail-Adresse
      <a
        class="decoration-primary underline"
        href="mailto:info@zimmerpflanzen.de"
        >{{ "info@zimmerpflanzen.de" }}</a
      >
    </p>
    <p>Bitte geben Sie uns folgende Informationen an:</p>
    <ul>
      <li>Bestellnummer</li>
      <li>Fotos der Pflanze inkl. Verpackung</li>
    </ul>
    <p>
      Wir werden uns schnellstmöglich um Ihr Anliegen kümmern und Ihnen eine
      Lösung anbieten.
    </p>
  </div>
</app-container>
