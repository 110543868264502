import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons';
import { faMinus } from '@fortawesome/pro-duotone-svg-icons';
import { debounceTime, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventDirective } from '../../event.directive';

@Component({
    selector: 'app-stepper',
    imports: [FaIconComponent, EventDirective],
    templateUrl: './stepper.component.html',
    styleUrl: './stepper.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  value = input.required<number>();
  valueChange = output<number>();

  current = 0;

  protected readonly faPlus = faPlus;
  protected readonly faMinus = faMinus;

  private updateSubject = new Subject<number>();

  ngOnInit() {
    this.current = this.value();
    this.updateSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(debounceTime(200))
      .subscribe((value) => {
        this.valueChange.emit(value);
      });
  }

  increment() {
    this.current += 1;
    this.updateSubject.next(this.current);
  }

  decrement() {
    this.current -= 1;
    this.updateSubject.next(this.current);
  }
}
