import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  viewChild,
} from '@angular/core';
import { EventDirective } from '../../event.directive';

export interface VideoSource {
  src: string;
  type: string;
}

@Component({
  selector: 'app-video',
  imports: [EventDirective],
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent {
  sources = input.required<VideoSource[]>();
  poster = input.required<string>();
  video = viewChild<ElementRef<HTMLVideoElement>>('player');

  constructor() {
    afterNextRender(async () => {
      const Plyr = await import('plyr');
      const video = this.video();
      if (video) {
        new Plyr.default(video.nativeElement, {
          autoplay: false,
          controls: ['play-large', 'progress'],
        });
      }
    });
  }
}
