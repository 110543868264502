import { ChangeDetectionStrategy, Component } from '@angular/core';
import {ContainerComponent} from "../container/container.component";

@Component({
    selector: 'app-section-header',
    imports: [
        ContainerComponent
    ],
    templateUrl: './section-header.component.html',
    styleUrl: './section-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionHeaderComponent {

}
