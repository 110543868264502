import { inject, Injectable, makeStateKey } from '@angular/core';
import { detailStore } from '../detailStore';
import { ListState } from '../listStore';
import { StoreApiService } from '../store-api.service';
import { Schemas } from '../../api-types/storeApiTypes';

@Injectable({
  providedIn: 'root',
})
export class OrderStateService {
  private readonly storeApi = inject(StoreApiService);

  readonly ordersStore = this.storeApi.createListStore(
    makeStateKey<ListState<Schemas['Order']>>('order-list'),
    () =>
      this.storeApi.apiClient
        .invoke('readOrder post /order', { body: {} })
        .then((r) => ({
          elements: r.data.orders.elements as Schemas['Order'][],
          total: r.data.orders.total ?? 0,
        })),
  );

  readonly detailStore = detailStore(
    this.ordersStore,
    (order) => order.id,
    (orderId, signal) =>
      this.storeApi.apiClient
        .invoke('readOrder post /order', {
          body: {
            associations: {
              lineItems: {},
            },
          },
          fetchOptions: { signal },
        })
        .then((r) => r.data.orders.elements[0]),
  );
}
