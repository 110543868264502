<div class="mx-auto" itemscope itemtype="https://schema.org/FAQPage">
  <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">
    FAQ
  </h2>
  <p class="mt-4 text-base text-gray-500">
    Die wichtigesten Fragen und Antworten rund um das Themen Zimmerpflanzen.
  </p>

  <dl class="mt-10">
    @for (item of items(); let index = $index; track index) {
      <dt
        class="py-6 flex flex-col border-b border-gray-900/10"
        itemscope
        itemprop="mainEntity"
        itemtype="https://schema.org/Question"
      >
        <!-- Expand/collapse question button -->
        <button
          (click)="toggle(item)"
          type="button"
          class="flex w-full items-start justify-between text-left text-gray-900"
          [attr.aria-controls]="'faq-' + item.id"
          aria-expanded="false"
        >
          <span class="text-base font-semibold leading-7" itemprop="name">{{
            item.question
          }}</span>
          <span class="ml-6 flex h-7 items-center">
            <svg
              [ngClass]="{ hidden: collapsedItem() !== item }"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            <svg
              [ngClass]="{ hidden: collapsedItem() === item }"
              class="hidden h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M18 12H6"
              />
            </svg>
          </span>
        </button>
      </dt>
      <dd
        class="mt-2 pr-12 overflow-hidden transition-all"
        id="faq-{{ item.id }}"
        itemscope
        itemprop="suggestedAnswer acceptedAnswer"
        itemtype="https://schema.org/Answer"
        [ngClass]="{ 'max-h-0': collapsedItem() !== item }"
      >
        <p class="text-base leading-7 text-gray-600" itemprop="text">
          {{ item.answer }}
        </p>
      </dd>
    }
  </dl>
</div>
