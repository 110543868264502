<app-container>
  <article class="lg:ml-auto lg:pl-4 lg:pt-4">
    <div class="lg:max-w-lg">
      <p class="text-base font-semibold leading-7 text-primary">
        {{ teaser() }}
      </p>
      <h3
        class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
      >
        {{ heading() }}
      </h3>
      <p class="mt-6 text-lg leading-8 text-gray-600">{{ text() }}</p>
      <ng-content></ng-content>
    </div>
  </article>
</app-container>
