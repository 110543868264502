import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  signal,
} from '@angular/core';
import { NavbarItemComponent } from './navbar-item/navbar-item.component';
import {
  faBars,
  faHeartCircle,
  faUser,
} from '@fortawesome/pro-duotone-svg-icons';
import { faSeedling } from '@fortawesome/pro-duotone-svg-icons';
import { faCartShopping } from '@fortawesome/pro-duotone-svg-icons';
import { ResolveStart, Router, RouterLink } from '@angular/router';
import { ContainerComponent } from '../container/container.component';
import { StaticAssetComponent } from '../static-asset/static-asset.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CartService } from '../../cart.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SidebarComponent } from '../../sidebar/sidebar.component';
import { CustomerStateService } from '../../account/customer-state.service';

@Component({
  selector: 'app-navbar',
  imports: [
    NavbarItemComponent,
    RouterLink,
    ContainerComponent,
    StaticAssetComponent,
    FaIconComponent,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  private readonly cart = inject(CartService);
  private readonly customerState = inject(CustomerStateService);

  sidebar = input.required<SidebarComponent>();

  hamburgerIcon = faBars;

  items = [
    // {
    //   icon: faComments,
    //   text: 'Chat',
    //   link: '/Chat',
    //   class: '',
    //   match: { exact: true, links: ['/Chat'] },
    //   active: signal(false),
    // },
    // {
    //   icon: faHome,
    //   text: 'Home',
    //   link: '/',
    //   class: 'hidden md:block',
    //   match: { exact: true, links: ['/'] },
    //   active: signal(false),
    //   badge: signal(null),
    // },
    {
      icon: faSeedling,
      text: 'Shop',
      link: '/Zimmerpflanzen',
      class: 'hidden md:block',
      match: {
        exact: false,
        links: ['/Zimmerpflanzen', '/Blumentoepfe', '/Zubehoer'],
        default: true,
      },
      visible: signal(true),
      active: signal(false),
      badge: signal(null),
    },
    {
      icon: faCartShopping,
      text: 'Warenkorb',
      link: '/checkout',
      badge: computed(() => {
        const cart = this.cart.store();
        if (cart.loading) {
          return null;
        } else {
          return (cart.lineItems ?? []).reduce(
            (sum, item) => sum + item.quantity,
            0,
          );
        }
      }),
      class: 'md:hidden',
      match: { exact: false, links: ['/checkout'] },
      active: signal(false),
      visible: signal(true),
    },
    {
      icon: faUser,
      text: 'Konto',
      link: '/account/login',
      badge: signal(null),
      visible: this.customerState.isLoggedOut,
      class: 'hidden md:block',
      match: { exact: false, links: ['/account'] },
      active: signal(false),
    },
    {
      icon: faUser,
      text: 'Konto',
      link: '/account/overview',
      badge: signal(null),
      visible: this.customerState.isLoggedIn,
      class: 'hidden md:block',
      match: { exact: false, links: ['/account'] },
      active: signal(false),
    },
    {
      icon: faHeartCircle,
      text: 'Wunschliste',
      link: '/account/wishlist',
      badge: signal(null),
      visible: this.customerState.isLoggedIn,
      class: 'hidden md:block',
      match: { exact: true, links: ['/account/wishlist'] },
      active: signal(false),
    },
    {
      icon: faCartShopping,
      text: 'Warenkorb',
      link: '/checkout/cart_checkout',
      visible: signal(true),
      badge: computed(() => {
        const cart = this.cart.store();
        if (cart.loading) {
          return null;
        } else {
          return (cart.lineItems ?? []).reduce(
            (sum, item) => sum + item.quantity,
            0,
          );
        }
      }),
      class: 'hidden md:block',
      match: { exact: false, links: ['/checkout'] },
      active: signal(false),
    },
    // {
    //   icon: faUser,
    //   text: 'Konto',
    //   link: '/account'
    // },
    // {
    //   icon: faInfoCircle,
    //   text: 'Informationen',
    //   link: '/Informationen',
    //   class: 'md:hidden',
    //   match: { exact: false, links: ['/Informationen'] },
    //   active: signal(false),
    //   badge: signal(null),
    // },
  ];

  activeIndex = signal(0);
  defaultIndex = 1;

  router = inject(Router);
  destroyRef = inject(DestroyRef);

  constructor() {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((evt) => {
        if (evt instanceof ResolveStart) {
          const activeItem = this.getActiveItem(evt.url);
          for (const item of this.items) {
            item.active.set(item === activeItem);
          }
          this.activeIndex.set(this.items.indexOf(activeItem));
        }
      });
  }

  getActiveItem(url: string) {
    for (const item of this.items) {
      if (item.match.exact && item.match.links.some((l) => l === url)) {
        return item;
      } else if (
        !item.match.exact &&
        item.match.links.some((l) => url.startsWith(l))
      ) {
        return item;
      }
    }
    return this.items[this.defaultIndex];
  }
}
