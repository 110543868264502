import { inject, Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root',
})
export class CookieStateService {
  cookies = inject(SsrCookieService);

  cookieConsent = this.cookies.get('cookie-consent');

  accept(consent: { functional: boolean, analytics: boolean, marketing: boolean }) {
    const duration = consent.analytics && consent.marketing ? 365 : 7;
    this.cookies.set('cookie-preference', '1', duration);
    if (consent.analytics) {
      this.cookies.set('google-analytics-enabled', '1', duration);
    }
    if (consent.marketing) {
      this.cookies.set('google-ads-enabled', '1', duration);
    }
    this.cookies.set('cookie-consent', JSON.stringify(consent), duration);
    this.cookieConsent = JSON.stringify(consent);
  }
}
