import { Injectable, signal } from '@angular/core';
import { ActionSheetComponent } from './action-sheet.component';

@Injectable({
  providedIn: 'root',
})
export class ActionSheetStateService {
  private activeSheet = signal<ActionSheetComponent | null>(null);

  setActive(sheet: ActionSheetComponent) {
    const current = this.activeSheet();
    if (current !== null && current !== sheet) {
      current.close();
    }
    this.activeSheet.set(sheet);
  }
}
