<div class="flex space-x-3 w-full">
  <div
    class="md:rounded-md px-6 flex-grow min-w-0 bg-white border-b border-b-gray-300 md:border-0 shadow h-12 w-full flex items-center group group-focus:ring-primary"
  >
    <span class="md:hidden">Suche</span>
    <input
      aria-label="Suche"
      [value]="products.queryFilter().text"
      (input)="updateSearch($event)"
      class="outline-none border-none focus:text-left transition-all text-right focus:outline-none focus:border-none focus:ring-0 text-gray-600 flex-grow min-w-0"
    />
    <fa-icon [icon]="faSearch" class="text-gray-500 flex-shrink-0"></fa-icon>
  </div>
</div>
