<app-header [header]="'Warenkorb'"></app-header>
@if (store(); as cart) {
  @if (!cart.loading) {
    <div
      class="flex flex-col flex-grow md:flex-grow-0 min-h-0 space-y-6 md:pt-12 md:mx-auto md:space-y-0"
    >
      @if (cart.lineItems && cart.lineItems.length > 0) {
        <app-cart-items [cartState]="cart"></app-cart-items>
        <app-cart-shipping [cartState]="cart"></app-cart-shipping>
        <app-cart-promotion-code [cartState]="cart"></app-cart-promotion-code>
        <app-cart-total [cartState]="cart"></app-cart-total>

        @if (cart.lineItems && cart.lineItems.length > 0) {
          <div class="bg-white sticky bottom-0 border-t">
            <app-container>
              <app-button-primary class="my-4" [action]="checkout"
                >Zur Kasse
              </app-button-primary>
            </app-container>
          </div>
        }
      }

      @if (!cart.loading && cart.lineItems && cart.lineItems.length === 0) {
        <app-cart-empty [cartState]="cart"></app-cart-empty>
      }
    </div>
  }
}
