import { inject, Injectable, makeStateKey } from '@angular/core';
import { ListState } from './listStore';
import { StoreApiService } from './store-api.service';
import { ProductCategory } from './productCategory';
import { productAssociations } from './productAssociations';
import { Schemas } from '../api-types/storeApiTypes';
import { environment } from '../environments/environment';
import { CategoryIds } from './categoryIds';

export const TopCategoryIds: Record<ProductCategory, string> = {
  Pot: environment.categories.pot,
  Plant: environment.categories.plant,
  Accessory: environment.categories.accessory,
};

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private readonly store = inject(StoreApiService);

  readonly topHitPlants = this.store.createListStore(
    makeStateKey<ListState<Schemas['Product']>>('hit-plants'),
    (signal) =>
      this.store.getProducts(
        {
          limit: 4,
          associations: productAssociations,
          filter: [
            {
              field: 'categories.id',
              value: CategoryIds['Plant'],
              type: 'equals',
            },
            {
              field: 'markAsTopseller',
              value: true,
              type: 'equals',
            },
          ],
          sort: [
            { field: 'stock', order: 'DESC' },
            { field: 'releaseDate', order: 'DESC' },
          ],
        },
        signal,
      ),
  );

  readonly topPlants = this.store.createListStore(
    makeStateKey<ListState<Schemas['Product']>>('top-plants'),
    (signal) =>
      this.store.getProducts(
        {
          limit: 4,
          associations: productAssociations,
          filter: [
            {
              field: 'categories.id',
              value: TopCategoryIds['Plant'],
              type: 'equals',
            },
          ],
          sort: [
            { field: 'stock', order: 'DESC' },
            { field: 'releaseDate', order: 'DESC' },
          ],
        },
        signal,
      ),
  );

  readonly topPots = this.store.createListStore(
    makeStateKey<ListState<Schemas['Product']>>('top-pots'),
    (signal) =>
      this.store.getProducts(
        {
          limit: 4,
          associations: productAssociations,
          filter: [
            {
              field: 'categories.id',
              value: TopCategoryIds['Pot'],
              type: 'equals',
            },
          ],
          sort: [
            { field: 'stock', order: 'DESC' },
            { field: 'releaseDate', order: 'DESC' },
          ],
        },
        signal,
      ),
  );

  readonly topAccessories = this.store.createListStore(
    makeStateKey<ListState<Schemas['Product']>>('top-accessories'),
    (signal) =>
      this.store.getProducts(
        {
          limit: 4,
          associations: productAssociations,
          filter: [
            {
              field: 'categories.id',
              value: TopCategoryIds['Accessory'],
              type: 'equals',
            },
          ],
          sort: [
            { field: 'stock', order: 'DESC' },
            { field: 'releaseDate', order: 'DESC' },
          ],
        },
        signal,
      ),
  );
}
