import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ContainerComponent } from '../../components/container/container.component';
import { MetaService } from '../../meta.service';

@Component({
    selector: 'app-shipping',
    imports: [HeaderComponent, ContainerComponent],
    templateUrl: './shipping.component.html',
    styleUrl: './shipping.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingComponent {
  meta = inject(MetaService);

  constructor() {
    this.meta.setMetaTags(
      {
        keywords: 'zimmerpflanzen, Schadensmeldung',
        metaTitle: 'Alles Wissenswerte über den Versand von Zimmerpflanzen',
        metaDescription:
          'Erfahren Sie alles über den Versand unserer Zimmerpflanzen: Versandkosten, Lieferzeiten und verfügbare Liefergebiete. Schnelle und zuverlässige Lieferung direkt zu Ihnen nach Hause. Entdecken Sie jetzt die Details!',
      },
      undefined,
    );
  }
}
