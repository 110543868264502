<app-navbar [sidebar]="sidebar"></app-navbar>

<app-sidebar #sidebar></app-sidebar>
<app-signup-sidebar #signupSidebar></app-signup-sidebar>
<div
  class="flex-grow flex flex-col min-h-0 bg-gradient-to-b from-gray-100 via-gray-50 to-gray-200 w-full"
>
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
<app-cookie-banner></app-cookie-banner>
