<main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
  <app-static-asset [alt]="'not found, 404'" [extension]="'png'" [source]="'assets/static/404'" class="h-64"></app-static-asset>

  <div class="text-center">
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Seite nicht gefunden</h1>
    <p class="mt-6 text-base leading-7 text-gray-600">
      Die Seite, die du suchst, existiert nicht oder wurde verschoben.
    </p>

    <app-button-primary [action]="goToShop" class="mt-8">
      Zurück zum Shop
    </app-button-primary>
  </div>
</main>
