import { signal, Signal } from '@angular/core';
import { ListState } from './listStore';

export type DetailState<T> =
  | { loading: true }
  | { loading: false; element: T | null };

export interface DetailStore<T> {
  state: Signal<DetailState<T>>;
  onChange(fn: (val: T | null) => void): void;
  set(id: string): Promise<void>;
}

export function detailStore<T>(
  listStore: Signal<ListState<T>>,
  keySelector: (element: T) => string,
  fetch: (id: string, abort: AbortSignal) => Promise<T | undefined | null>,
): DetailStore<T> {
  const state = signal<DetailState<T>>({ loading: true });
  const callbacks: ((val: T | null) => void)[] = [];

  return {
    state,
    async set(id: string): Promise<void> {
      const list = listStore();
      if (list.started) {
        for (const element of list.elements) {
          const key = keySelector(element);
          if (key === id) {
            state.set({ loading: false, element });
            return;
          }
        }
      }

      const element = await fetch(id, new AbortController().signal);
      state.set({ loading: false, element: element ?? null });
      for (const callback of callbacks) {
        callback(element ?? null);
      }
    },
    onChange: (fn) => {
      const currentState = state();
      fn(currentState.loading ? null : currentState.element);
      callbacks.push(fn);
    },
  };
}
