import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { RouterLink } from '@angular/router';
import { StaticAssetComponent } from '../static-asset/static-asset.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBlog } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'app-footer',
    imports: [
        ContainerComponent,
        RouterLink,
        StaticAssetComponent,
        FaIconComponent,
    ],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  instagramIcon = faInstagram;
  youtubeIcon = faYoutube;
  blogIcon = faBlog;
}
