<app-collapsable-option-group
  [label]="'Sortierung'"
  [(open)]="open"
  [showLabel]="showLabel()"
  [collapsable]="collapsable()"
  [selected]="[products.queryFilter().sort.label]"
>
  <app-collapsable-option-header>
    {{ products.queryFilter().sort.label }}
  </app-collapsable-option-header>
  @for (sortOption of products.sortOptions; track sortOption.field) {
    <app-collapsable-option
      [label]="sortOption.label"
      [id]="sortOption.label"
      (selected)="selectSort(sortOption)"
    ></app-collapsable-option>
  }
</app-collapsable-option-group>
