<app-header [header]="'Versandinformationen'"></app-header>

<!--<img src="assets/versand2.png" alt="versand" class="bg-white" />-->

<app-container>
  <div class="prose py-8">
    <p>
      Wir möchten Ihnen eine angenehme und transparente Einkaufserfahrung
      bieten. Bitte beachten Sie unsere Versandrichtlinien:
    </p>

    <h2>Liefergebiete</h2>
    <p>
      Wir versenden derzeit innerhalb Deutschland, wobei wir stets daran
      arbeiten, unseren Versandservice zu erweitern, um Ihnen Europaweiten
      Versand anbieten zu können.
    </p>

    <h2>Lieferzeit</h2>
    <p>
      Unsere übliche Lieferzeit beträgt in der Regel eine Woche nach
      Auftragsbestätigung und erfolgreicher Zahlung. Bitte beachten Sie, dass
      Feiertage oder unvorhergesehene Umstände zu leichten Verzögerungen führen
      können. Wir tun jedoch unser Bestes, um sicherzustellen, dass Ihre
      Bestellung so schnell wie möglich bearbeitet und versendet wird.
    </p>

    <h2>Versandkosten</h2>
    <p>
      Bestellungen mit einem Warenwert unter 60 Euro haben Versandkosten in Höhe
      von 6,99 Euro. Ab einem Warenwert von 60 Euro ist der Versand kostenlos.
    </p>

    <h2>Sendungsverfolgung</h2>
    <p>
      Sobald Ihre Bestellung versandt wurde, erhalten Sie eine
      Versandbestätigung per E-Mail, die einen Tracking-Link enthält. Damit
      können Sie den Status Ihrer Sendung verfolgen und den voraussichtlichen
      Liefertermin einsehen.
    </p>
  </div>
</app-container>
