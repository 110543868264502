import { inject, Injectable, makeStateKey } from '@angular/core';
import { relatedFilterStore } from './storedFilterStore';
import { FilteredListState, FilterState } from './filteredListStore';
import { detailStore } from './detailStore';
import { PropertyGroupIds } from './property.service';
import { components, Schemas } from '../api-types/storeApiTypes';
import { StoreApiService } from './store-api.service';
import { propertyFilter } from './property.filter';
import { productAssociations } from './productAssociations';
import { ProductListService } from './product-list.service';
import { CustomerStateService } from './account/customer-state.service';

export interface FamilyPlantFilter extends FilterState {
  family: string;
  productId: string;
  sort: components['schemas']['Sort'];
}

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private readonly store = inject(StoreApiService);
  private readonly productList = inject(ProductListService);
  private readonly customerState = inject(CustomerStateService);

  async review(
    productId: string,
    body: {
      content: string;
      email?: string;
      points: number;
      title: string;
    },
  ) {
    const signedUp = await this.customerState.ensureSignedUp();
    if (!signedUp) {
      return;
    }

    return this.store.apiClient.invoke(
      'saveProductReview post /product/{productId}/review',
      {
        pathParams: { productId },
        body: {
          content: body.content,
          email: body.email,
          name: signedUp.firstName,
          points: body.points,
          title: body.title,
        },
      },
    );
  }

  readonly detailStore = detailStore<Schemas['Product']>(
    this.productList.productStore,
    (prod) => prod.productNumber,
    (productNumber, signal) =>
      this.store
        .getProducts(
          {
            limit: 1,
            associations: productAssociations,
            filter: [
              { field: 'productNumber', value: productNumber, type: 'equals' },
            ],
          },
          signal,
        )
        .then((r) => r.elements[0]),
  );

  readonly familyPlantFilter = relatedFilterStore<
    FamilyPlantFilter,
    Schemas['Product']
  >(
    this.detailStore,
    {
      family: '',
      productId: '',
      sort: { field: 'releaseDate', order: 'DESC' },
      appliedQuery: [],
    },
    (detail, filter) => {
      filter.productId = detail?.id ?? '';
      filter.family =
        detail?.properties?.find(
          (prop) => prop?.groupId === PropertyGroupIds['plant-family'],
        )?.translated['name'] ?? '';
      return filter;
    },
  );

  readonly familyPlantStore = this.store.createFilteredStore<
    FamilyPlantFilter,
    Schemas['Product']
  >(
    makeStateKey<FilteredListState<FamilyPlantFilter, Schemas['Product']>>(
      'family-plants',
    ),
    this.familyPlantFilter.state,
    (filter, skip, signal) => {
      return filter.family && filter.productId
        ? this.store.getProducts(
            {
              limit: 4,
              aggregations: [],
              associations: productAssociations,
              page: skip / 4 + 1,
              filter: [
                {
                  field: 'stock',
                  type: 'range',
                  parameters: {
                    gte: 1,
                  },
                },
                propertyFilter(PropertyGroupIds['plant-family'], [
                  filter.family,
                ]),
                {
                  type: 'not',
                  queries: [
                    { type: 'equals', field: 'id', value: filter.productId },
                  ],
                } as components['schemas']['MultiNotFilter'],
              ],
              sort: [filter.sort, { field: 'name', order: 'ASC' }],
            },
            signal,
          )
        : Promise.resolve({
            elements: [] as Schemas['Product'][],
            total: 0,
            aggregations: {
              filters: {
                buckets: [],
              },
              properties: {
                buckets: [],
              },
              stock: {
                buckets: [],
              },
            },
          });
    },
  );
}
