<app-action-sheet #sort [heading]="'Sortierung'">
  <div class="">
    <app-product-sort
      [open]="true"
      [collapsable]="false"
      (selected)="sort.close()"
    ></app-product-sort>
  </div>
</app-action-sheet>

<app-action-sheet #categories [heading]="'Kategorie'">
  <div class="">
    <app-product-categories
      [open]="true"
      [collapsable]="false"
      (selected)="categories.close()"
    ></app-product-categories>
  </div>
</app-action-sheet>

<app-action-sheet #filter [heading]="'Filter'">
  <div class="">
    <app-product-filter
      [open]="true"
      [collapsable]="false"
    ></app-product-filter>
  </div>
</app-action-sheet>

<div class="shadow h-16">
  <app-container>
    <div class="flex items-center justify-between h-full">
      <button class="action-btn" (click)="openSheet(categories)">
        <fa-icon [icon]="categoryIcon"></fa-icon>
      </button>
      <button class="action-btn" (click)="openSheet(sort)">
        <fa-icon [icon]="sortIcon"></fa-icon>
      </button>
      <button class="action-btn" (click)="openSheet(filter)">
        <fa-icon [icon]="filterIcon"></fa-icon>
      </button>
    </div>
  </app-container>
</div>
