import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';
import { InputComponent } from '../../components/input/input.component';
import { SectionHeaderComponent } from '../../components/section-header/section-header.component';
import { SectionItemComponent } from '../../components/section-item/section-item.component';
import { SectionItemsComponent } from '../../components/section-items/section-items.component';
import { CartService } from '../../cart.service';
import { Schemas } from '../../../api-types/storeApiTypes';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-cart-promotion-code',
    imports: [
        ButtonPrimaryComponent,
        InputComponent,
        SectionHeaderComponent,
        SectionItemComponent,
        SectionItemsComponent,
    ],
    templateUrl: './cart-promotion-code.component.html',
    styleUrl: './cart-promotion-code.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartPromotionCodeComponent {
  cart = inject(CartService);
  fb = inject(FormBuilder);
  cartState = input.required<Schemas['Cart']>();

  cartForm = this.fb.group({
    promotionCode: this.fb.control('', []),
  });
  applyCoupon = async () => {
    const code = this.cartForm.value.promotionCode;
    if (!code) {
      return;
    }
    await this.cart.addPromotionCode(code);
    this.cartForm.controls.promotionCode.patchValue('');
  };
}
