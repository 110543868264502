import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-collapsable-header',
    imports: [],
    templateUrl: './collapsable-header.component.html',
    styleUrl: './collapsable-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsableHeaderComponent {

}
