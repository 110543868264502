<h2 class="decoration-primary-orange underline text-lg">Unsere Vorteile</h2>
@if (product().stock === 0) {
  <div class="flex space-x-2">
    <fa-icon [icon]="faTimes" class="text-red-900"></fa-icon>
    <div>Produkt nicht an Lager</div>
  </div>
} @else {
  <div class="flex space-x-2">
    <fa-icon [icon]="faCheck" class="text-primary-orange"></fa-icon>
    <div>Produkt an Lager</div>
  </div>
}
<div class="flex space-x-2">
  <fa-icon [icon]="faCheck" class="text-primary-orange"></fa-icon>
  <div>
    Zuhause innerhalb von
    {{ product().deliveryTime?.translated?.name }}
  </div>
</div>
@if (isPlant()) {
  <div class="flex space-x-2">
    <fa-icon [icon]="faCheck" class="text-primary-orange"></fa-icon>
    <div>Direkt vom Züchter</div>
  </div>
}
<div class="flex space-x-2">
  <fa-icon [icon]="faCheck" class="text-primary-orange"></fa-icon>
  <div>Versandkostenfrei ab 60€</div>
</div>

@if (priceOptions(); as priceOptions) {
  @if (priceOptions.length > 0) {
    <div class="flex space-x-2">
      <fa-icon [icon]="faCheck" class="text-primary-orange"></fa-icon>
      <span>Profitiere von Mengenrabatt</span>
    </div>

    @for (price of priceOptions; track $index) {
      <div class="ml-6 text-gray-700">
        <span class="flex items-center">
          <span id="pricing-plans-0-label" class="font-medium"
            >{{ price.quantity }} Stück für {{ price.totalPrice | currency }} -
            <span class="underline decoration-primary-orange"
              >{{ price.savings | percent }} Rabatt</span
            ></span
          >
          <span></span>
        </span>
      </div>
    }
  }
}
