import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-stars',
    imports: [NgClass],
    templateUrl: './stars.component.html',
    styleUrl: './stars.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarsComponent {
  points = [1, 2, 3, 4, 5];
  size = input<string>('w-5 h-5');

  selectedPoint = input.required<number | undefined>();
  pointSelected = output<number>();
}
