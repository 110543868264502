import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faFilter,
  faSearch,
  faShop,
  faSort,
} from '@fortawesome/pro-duotone-svg-icons';
import { ContainerComponent } from '../../components/container/container.component';
import { ActionSheetComponent } from '../../components/action-sheet/action-sheet.component';
import { ProductSortComponent } from '../product-sort/product-sort.component';
import { ProductFilterComponent } from '../product-filter/product-filter.component';
import { ProductCategoriesComponent } from '../product-categories/product-categories.component';

@Component({
    selector: 'app-product-list-toolbar',
    imports: [
        FaIconComponent,
        ContainerComponent,
        ActionSheetComponent,
        ProductSortComponent,
        ProductFilterComponent,
        ProductCategoriesComponent,
    ],
    templateUrl: './product-list-toolbar.component.html',
    styleUrl: './product-list-toolbar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductListToolbarComponent {
  searchIcon = faSearch;
  filterIcon = faFilter;
  sortIcon = faSort;
  categoryIcon = faShop;

  openSheet(sort: ActionSheetComponent) {
    sort.toggle();
  }
}
