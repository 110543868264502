@if (
  products.productStore().availableFilters.length > 0 ||
  appliedFilters().length > 0
) {
  <app-collapsable-option-group
    [label]="'Filter'"
    [selected]="[]"
    [showLabel]="showLabel()"
    [(open)]="open"
    [collapsable]="collapsable()"
  >
    <app-collapsable-option-header>
      {{ filterDescription() }}
    </app-collapsable-option-header>

    @if (appliedFilters(); as appliedFilters) {
      @if (appliedFilters.length > 0) {
        <div class="mb-4">
          @for (appliedFilter of appliedFilters; track appliedFilter.type) {
            <div class="flex flex-wrap">
              @for (value of appliedFilter.values; track value.id) {
                <button
                  (click)="reset(appliedFilter.type, value, $event)"
                  class="text-white rounded text-sm mb-1 w-full mr-1 text-left flex justify-between active"
                  aria-label="Filter löschen"
                >
                  <span
                    class="bg-primary rounded-l flex-grow basis-1 px-3 py-2"
                    >{{ appliedFilter.type }}</span
                  >
                  <span
                    class="bg-primary/90 rounded-r text-right flex-grow basis-1 px-3 py-2"
                  >
                    <span>{{ value.label }}</span>
                    <fa-icon [icon]="faTimes" class="ml-2"></fa-icon>
                  </span>
                </button>
              }
            </div>
          }
        </div>
      }
    }

    @for (filter of products.productStore().availableFilters; track filter.id) {
      <div class="border border-gray-200 bg-white mb-4 rounded-md">
        <div class="flex-grow px-3 font-bold py-2 min-w-0 flex justify-between">
          <span>{{ filter.label }}</span>
        </div>

        <div class="px-2">
          @for (option of filter.options; track option.id) {
            <app-collapsable-option
              [label]="option.label"
              [id]="option.id"
              [appEvent]="'filter'"
              class="border-y border-gray-300"
              (selected)="select(filter, option)"
              [appEventData]="{ filter: filter.label, value: option.label }"
            >
              <span class="bg-white/30 rounded px-2">{{ option.count }}</span>
            </app-collapsable-option>
          }
        </div>
      </div>
    }
  </app-collapsable-option-group>
}
