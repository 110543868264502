import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-collapsable-body',
    imports: [],
    templateUrl: './collapsable-body.component.html',
    styleUrl: './collapsable-body.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsableBodyComponent {

}
