<app-header [header]="'Zahlung fehlgeschlagen'"></app-header>
<app-container>
  <div class="my-8 max-w-xl mx-auto space-y-4" *ngIf="order() as state">
    @if (state.loading) {
      <app-spinner></app-spinner>
    } @else {
      @if (state.element) {
        <div class="mb-3 bg-primary/10 text-primary px-4 py-2 rounded w-full">
          <h2 class="font-bold">Zahlung wurde nicht abgeschlossen</h2>
          <p>
            Leider ist die Zahlung fehlgeschlagen. Bitte versuche es erneut oder
            kontaktiere unseren Support.
          </p>
        </div>

        <div class="bg-white rounded px-4 py-4">
          <h1 class="font-bold">
            Auftrag #{{ state.element.id.substring(0, 8) }}
          </h1>
          <div>
            @for (item of state.element.lineItems; track item.id) {
              <div class="relative">
                <div
                  class="h-full py-2 relative rounded-b-md flex flex-grow min-w-0 items-center"
                >
                  <div class="space-y-1 w-full">
                    <p class="line-clamp-1 flex-grow min-h-0">
                      {{ item.label }}
                    </p>
                    <div>
                      {{ item.quantity }}x
                      {{ item.unitPrice | currency }}
                    </div>
                  </div>
                  <div>
                    {{ item.totalPrice | currency }}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
      <div class="bg-white rounded px-4 py-4">
        <h2 class="font-bold mb-2">Zahlungsmittel</h2>
        <app-payment-options
          [paymentMethodControl]="form.controls.paymentMethodId"
        ></app-payment-options>
      </div>

      <app-button-primary
        class=""
        [action]="retryPayment"
        [disabled]="!form.valid"
        >Zahlung wiederholen</app-button-primary
      >

      @if (errorMessage()) {
        <div class="px-4 py-2 bg-red-100 text-red-800 rounded">
          {{ errorMessage() }}
        </div>
      }
    }
  </div>
</app-container>
