import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';
import { animate } from 'motion';

@Component({
  selector: 'app-navbar-item',
  imports: [FaIconComponent, RouterLink, NgClass],
  templateUrl: './navbar-item.component.html',
  styleUrl: './navbar-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarItemComponent implements OnChanges {
  icon = input.required<IconDefinition>();
  link = input.required<string | string[]>();
  text = input.required<string>();
  active = input.required<boolean>();

  badge = input.required<number | null>();

  elm = inject(ElementRef);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['badge'] && !changes['badge'].firstChange) {
      const badge = this.elm.nativeElement.querySelector('.badge');
      if (!badge) {
        return;
      }

      animate(
        badge,
        { scale: [1, 1, 2, 2], opacity: [1, 1, 0, 0] },
        { duration: 1, easing: [0, 0, 0.2, 1], offset: [0, 0.5, 0.75, 1] },
      );
    }
  }
}
