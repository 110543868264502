<div
  class="flex flex-col flex-grow min-h-0 border-t border-gray-200 rounded-t-md bg-white"
>
  <div class="flex-shrink-0 flex items-center justify-between px-3 py-2">
    <div class="text-lg">{{ heading() }}</div>
    <button class="text-2xl" (click)="close()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div
    class="overflow-y-auto flex-grow min-h-0"
    [style.max-height]="'calc(100dvh - 110px)'"
  >
    <ng-content></ng-content>
  </div>
</div>
