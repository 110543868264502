import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  inject,
} from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { NgIf } from '@angular/common';
import { OrderStateService } from '../order-state.service';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { ContainerComponent } from '../../components/container/container.component';
import { AnalyticsService } from '../../analytics.service';

@Component({
    selector: 'app-order-success',
    imports: [
        HeaderComponent,
        NgIf,
        SpinnerComponent,
        ButtonPrimaryComponent,
        ContainerComponent,
    ],
    templateUrl: './order-success.component.html',
    styleUrl: './order-success.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSuccessComponent {
  orderState = inject(OrderStateService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  analytics = inject(AnalyticsService);

  order = this.orderState.detailStore.state;

  constructor() {
    afterNextRender(() => {
      this.analytics.event('conversion', {
        send_to: 'AW-11344968066/uOjBCP6WnsQZEILz2aEq',
        transaction_id: this.route.snapshot.params['orderId'],
      });

      this.analytics.event('purchase', {
        currency: 'EUR',
        transaction_id: this.route.snapshot.params['orderId'],
      });
    });
  }

  goToTheShop = () => {
    return this.router.navigate(['/']);
  };
}
