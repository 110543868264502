<app-section-items>
  <app-section-header>
    <span>Total</span>
    <span></span>
  </app-section-header>
  <app-nested-section>
    <app-section-item-values>
      <span>Warenkorb</span>
      <span>{{ cartState().price.netPrice | currency }}</span>
    </app-section-item-values>

    @for (delivery of cartState().deliveries; track $index) {
      <app-section-item-values>
        <span>Versand</span>
        <span>{{ delivery.shippingCosts?.totalPrice | currency }}</span>
      </app-section-item-values>
    }

    @for (price of cartState().price.calculatedTaxes; track price.taxRate) {
      @if (price.price > 0) {
        <app-section-item-values>
          <span>MWST {{ price.taxRate / 100 | percent }}</span>
          <span>{{ price.tax | currency }}</span>
        </app-section-item-values>
      }
    }
  </app-nested-section>
  <app-section-item>
    <app-section-item-values>
      <span>Total</span>
      <span>{{ cartState().price.totalPrice | currency }}</span>
    </app-section-item-values>
  </app-section-item>
</app-section-items>
