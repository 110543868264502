import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-section-items',
    imports: [],
    templateUrl: './section-items.component.html',
    styleUrl: './section-items.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionItemsComponent {

}
