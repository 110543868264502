import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { StaticAssetComponent } from '../../components/static-asset/static-asset.component';

@Component({
    selector: 'app-section-image',
    templateUrl: './section-image.component.html',
    styleUrl: './section-image.component.scss',
    imports: [StaticAssetComponent],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionImageComponent {
  header = input.required<string>();
  image = input.required<string>();
}
