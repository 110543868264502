<app-input-label [label]="label()" [control]="control()"></app-input-label>
<div class="mt-2">
  <textarea
    [formControl]="control()"
    [attr.id]="'form-field-' + label()"
    [ngClass]="{ 'ring-red-600': showError(), 'ring-gray-300': !showError() }"
    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
  >
  </textarea>
</div>
