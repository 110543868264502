import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ContainerComponent } from '../../components/container/container.component';
import { MetaService } from '../../meta.service';

@Component({
    selector: 'app-refund',
    imports: [HeaderComponent, ContainerComponent],
    templateUrl: './refund.component.html',
    styleUrl: './refund.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefundComponent {
  meta = inject(MetaService);

  constructor() {
    this.meta.setMetaTags(
      {
        keywords: 'zimmerpflanzen, Schadensmeldung',
        metaTitle:
          'Schadensmeldung bei Zimmerpflanzen: Alles, was Sie wissen müssen',
        metaDescription:
          'Erfahren Sie, wie Sie Schäden an Ihren Zimmerpflanzen richtig melden. Erhalten Sie hilfreiche Tipps und Anleitungen zur Schadensmeldung und Pflege Ihrer Pflanzen, um deren Gesundheit und Schönheit zu gewährleisten.',
      },
      undefined,
    );
  }
}
