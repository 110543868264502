<app-header [header]="'Datenschutz'"></app-header>

<app-container>
  <div class="prose py-8">
    <h2>Datenschutzerklärung</h2>

    <p>Verantwortlicher für die Datenverarbeitung ist:</p>

    <p>
      Tropico Trading GmbH<br />
      Industriestraße 10<br />
      6343 Rotkreuz<br />
      Schweiz
    </p>

    <p>Geschäftsführer: Pascal Bertschi, Jonathan Imhof</p>
    <p>
      Email:
      <a href="mailto:info@zimmerpflanzen.de">{{ "info@zimmerpflanzen.de" }}</a>
    </p>
    <p>
      Es erfüllt uns mit Freude, dass Sie sich für unseren Online-Shop
      interessieren. Der Schutz Ihrer Privatsphäre liegt uns besonders am
      Herzen. Im Folgenden möchten wir Sie detailliert über den Umgang mit Ihren
      Daten informieren.
    </p>

    <h3>Hosting und Zugriffsdaten</h3>
    <p>
      Sie können unsere Website besuchen, ohne persönliche Informationen
      preiszugeben. Beim Besuch einer Seite speichert der Webserver automatisch
      lediglich bestimmte Daten in einem Server-Logfile. Diese Daten umfassen
      beispielsweise den Dateinamen, Ihre IP-Adresse, Datum und Uhrzeit des
      Seitenaufrufs, die übertragene Datenmenge sowie den anfragenden Provider
      (Zugriffsdaten) und dokumentieren den Seitenbesuch. Die Auswertung dieser
      Zugriffsdaten erfolgt ausschließlich zur Gewährleistung eines
      reibungslosen Betriebs der Seite und zur Verbesserung unseres Angebots.
      Dies geschieht, um unsere legitimierten Interessen gemäß Art. 6 Abs. 1 S.
      1 lit. f DSGVO an einer angemessenen Darstellung unseres Angebots zu
      wahren. Alle Zugriffsdaten werden spätestens sieben Tage nach dem Ende
      Ihres Seitenbesuchs gelöscht, um Ihre Privatsphäre zu schützen.
    </p>

    <h4>Hosting</h4>
    <p>
      Unsere Dienstleister erbringen teilweise Hosting- und
      Webseitendarstellungs-Dienste in unserem Auftrag. Alle Zugriffsdaten und
      Informationen aus den auf dieser Webseite bereitgestellten Formularen
      werden, sofern in unserer Datenschutzerklärung nicht anders angegeben, auf
      den Servern unserer Dienstleister verarbeitet. Sie können sich bezüglich
      unserer Dienstleister und der Grundlage unserer Zusammenarbeit gerne über
      die Kontaktmöglichkeiten in unserer Datenschutzerklärung informieren.
      Unsere Dienstleister nutzen Server in Deutschland oder haben ihren Sitz
      dort. Diese entsprechen den Datenschutzstandards der Europäischen
      Kommission und gewährleisten somit ein angemessenes Schutzniveau für Ihre
      Daten.
    </p>

    <h3>Datenverarbeitung zur Vertragsabwicklung und zur Kontaktaufnahme</h3>
    <p>
      Zwecks Vertragsabwicklung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO erfassen
      wir personenbezogene Daten, die Sie uns freiwillig im Rahmen Ihrer
      Bestellung mitteilen. Pflichtfelder sind als solche gekennzeichnet, da wir
      diese Informationen zwingend für die Vertragsabwicklung benötigen und ohne
      sie die Bestellung nicht versendet werden kann. Die erforderlichen Daten
      sind in den jeweiligen Eingabeformularen angegeben.
    </p>
    <p>
      Weitere Einzelheiten zur Datenverarbeitung, insbesondere zur Weitergabe an
      Dienstleister für Bestellungs-, Zahlungs- und Versandabwicklung, finden
      Sie in den nachfolgenden Abschnitten unserer Datenschutzerklärung. Nach
      Abschluss des Vertrags werden Ihre Daten eingeschränkt und gemäß Art. 6
      Abs. 1 S. 1 lit. c DSGVO nach Ablauf der steuer- und handelsrechtlichen
      Aufbewahrungsfristen gelöscht, es sei denn, Sie haben ausdrücklich einer
      weiteren Verwendung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO zugestimmt oder
      es liegt eine gesetzlich erlaubte Datenverwendung vor, über die wir in
      dieser Erklärung informieren.
    </p>

    <h4>Warenwirtschaftssystem</h4>
    <p>
      Für die Abwicklung von Bestellungen und Verträgen nutzen wir externe
      Warenwirtschaftssysteme, die von Dienstleistern bereitgestellt werden.
      Diese Dienstleister handeln im Rahmen einer Auftragsverarbeitung für uns.
      Sollten Sie Fragen zu unseren Dienstleistern oder den Grundlagen unserer
      Zusammenarbeit mit ihnen haben, bitten wir Sie, sich über die in unserer
      Datenschutzerklärung genannten Kontaktmöglichkeiten an uns zu wenden.
    </p>

    <h4>Kundenkonto</h4>
    <p>
      Falls Sie durch die Entscheidung zur Eröffnung eines Kundenkontos gemäß
      Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre Einwilligung erteilt haben, nutzen
      wir Ihre Informationen, um Ihr Kundenkonto zu erstellen und Ihre Daten für
      künftige Bestellungen auf unserer Website zu speichern. Sie haben
      jederzeit die Möglichkeit, Ihr Kundenkonto zu löschen. Dies kann entweder
      durch Kontaktaufnahme über die in der Datenschutzerklärung genannten
      Möglichkeiten oder über die entsprechende Funktion in Ihrem Kundenkonto
      erfolgen. Nach der Löschung werden Ihre Daten entfernt, es sei denn, Sie
      haben explizit einer weiteren Nutzung gemäß Art. 6 Abs. 1 S. 1 lit. a
      DSGVO zugestimmt oder es besteht eine gesetzliche Grundlage oder eine
      darüber hinausgehende Datenverarbeitung, über die wir Sie in der Erklärung
      informieren.
    </p>

    <h4>Kontaktaufnahme</h4>
    <p>
      Im Zuge unserer Kommunikation mit Kunden erfassen wir personenbezogene
      Daten gemäß den Bestimmungen Art. 6 Abs. 1 S. 1 lit. b DSGVO. Diese Daten
      werden erhoben, wenn Sie uns diese freiwillig im Rahmen einer
      Kontaktaufnahme zur Verfügung stellen, beispielsweise über ein
      Kontaktformular oder per E-Mail. Pflichtfelder sind als solche
      gekennzeichnet, da wir in diesen Fällen die entsprechenden Informationen
      zwingend benötigen, um Ihre Anfrage zu bearbeiten. Die konkret erfassten
      Daten sind in den jeweiligen Eingabeformularen ersichtlich. Nach Abschluss
      der Bearbeitung Ihrer Anfrage löschen wir Ihre Daten, es sei denn, Sie
      haben ausdrücklich gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe a der DSGVO
      einer weiteren Verwendung Ihrer Daten zugestimmt. Es besteht ebenfalls die
      Möglichkeit, dass wir uns eine darüber hinausgehende Nutzung Ihrer Daten
      vorbehalten, sofern diese gesetzlich erlaubt ist. Über solche Fälle würden
      wir Sie in dieser Erklärung informieren.
    </p>

    <h3>Verarbeitung von Daten zur Abwicklung des Versands</h3>
    <p>
      Gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO werden Ihre Daten zur Erfüllung des
      Vertrages an den beauftragten Versanddienstleister weitergegeben, soweit
      dies zur Lieferung der bestellten Waren erforderlich ist. Gleiches gilt
      für die Übermittlung von Daten an unsere Hersteller oder Großhändler,
      sofern sie den Versand in unserem Auftrag übernehmen (sogenanntes
      Streckengeschäft). Diese werden gemäß dieser Datenschutzerklärung als
      Versanddienstleister betrachtet.
    </p>

    <h4>
      Weitergabe von Daten an Versanddienstleister zwecks
      Versandbenachrichtigung
    </h4>
    <p>
      Sofern Sie während oder nach Ihrer Bestellung Ihre explizite Zustimmung
      erteilt haben, gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO, übermitteln wir Ihre
      E-Mail-Adresse und Telefonnummer an den ausgewählten Versanddienstleister.
      Dies dient dem Zweck, dass der Versanddienstleister Sie vor der Zustellung
      kontaktieren kann, um die Lieferung anzukündigen oder abzustimmen.
    </p>
    <p>
      Sie haben jederzeit die Möglichkeit, Ihre Einwilligung zu widerrufen. Dies
      kann durch eine Nachricht an die in der Datenschutzerklärung angegebene
      Kontaktmöglichkeit oder direkt beim Versanddienstleister erfolgen, dessen
      Kontaktdaten unten aufgeführt sind. Nach dem Widerruf werden Ihre
      angegebenen Daten gelöscht, es sei denn, Sie haben ausdrücklich einer
      weiteren Verwendung Ihrer Daten zugestimmt oder wir sind gesetzlich
      befugt, Ihre Daten darüber hinaus zu verwenden und haben Sie darüber in
      dieser Erklärung informiert.
    </p>
    <p>
      {{ "Flora@home B.V." }}<br />
      2e Tochtweg 143 a<br />
      2913 LR Nieuwerkerk aan den IJssel<br />
      NL
    </p>

    <h3>Datenverarbeitung zur Zahlungsabwicklung</h3>
    <p>
      Für die Abwicklung von Zahlungen in unserem Online-Shop kooperieren wir
      mit verschiedenen Partnern, darunter technische Dienstleister,
      Kreditinstitute und Zahlungsdienstleister.
    </p>

    <h4>Datenverarbeitung zur Transaktionsabwicklung</h4>
    <p>
      Abhängig von der gewählten Zahlungsmethode übermitteln wir die zur
      Abwicklung der Zahlungstransaktion erforderlichen Daten an unsere
      technischen Dienstleister, die in unserem Auftrag handeln, an die
      beauftragten Kreditinstitute oder an den ausgewählten
      Zahlungsdienstleister. Dies geschieht im Rahmen der Vertragserfüllung
      gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO. Gelegentlich erheben die
      Zahlungsdienstleister die für die Zahlungsabwicklung benötigten Daten
      eigenständig, sei es auf ihrer eigenen Website oder durch technische
      Integration im Bestellprozess. In diesem Fall gilt die
      Datenschutzerklärung des jeweiligen Zahlungsdienstleisters. Für weitere
      Informationen über unsere Partner bei der Zahlungsabwicklung und die
      Grundlage unserer Zusammenarbeit mit ihnen verweisen wir auf die
      Kontaktmöglichkeiten, die in unserer Datenschutzerklärung beschrieben
      sind.
    </p>

    <h4>
      Verarbeitung von Daten zur Vorbeugung von Betrug und zur Verbesserung
      unserer Zahlungsabläufe
    </h4>
    <p>
      Bei Bedarf übermitteln wir unseren Dienstleistern möglicherweise weitere
      Daten, die sie als unsere Auftragsverarbeiter neben den erforderlichen
      Zahlungsdaten nutzen. Dies geschieht zum Zweck der Betrugsprävention und
      zur Verbesserung unserer Zahlungsabläufe, beispielsweise für
      Rechnungsstellung, die Abwicklung von strittigen Zahlungen und zur
      Unterstützung der Buchhaltung. Diese Maßnahme dient gemäß Art. 6 Abs. 1 S.
      1 lit. f DSGVO der Wahrung unserer berechtigten Interessen im Rahmen einer
      Abwägung, insbesondere unserem Schutz vor Betrug und einem effizienten
      Zahlungsmanagement.
    </p>

    <h3>Cookies und weitere Technologien</h3>
    <p>
      Um Ihr Webseiten-Erlebnis ansprechend zu gestalten und bestimmte
      Funktionen zu ermöglichen, setzen wir auf verschiedenen Seiten
      Technologien wie Cookies ein. Cookies sind kleine Textdateien, die
      automatisch auf Ihrem Endgerät gespeichert werden. Einige der von uns
      genutzten Cookies werden nach Ihrer Browser-Sitzung, also nachdem Sie
      Ihren Browser schließen, gelöscht (sogenannte Sitzungs-Cookies). Andere
      Cookies verbleiben auf Ihrem Endgerät und ermöglichen es uns, Ihren
      Browser bei Ihrem nächsten Besuch wiederzuerkennen (persistente Cookies).
    </p>

    <h4>Schutz der Privatsphäre bei Endgeräten</h4>
    <p>
      Für die Bereitstellung unseres Online-Angebots verwenden wir unbedingt
      notwendige Technologien, um den von Ihnen ausdrücklich gewünschten
      Telemediendienst bereitzustellen. In diesem Fall ist keine Einwilligung
      erforderlich für die Speicherung von Informationen in Ihrem Endgerät oder
      den Zugriff auf bereits gespeicherte Informationen. Jedoch bedarf es Ihrer
      Einwilligung für die Speicherung von Informationen in Ihrem Endgerät oder
      den Zugriff auf bereits gespeicherte Informationen bei Funktionen, die
      nicht zwingend erforderlich sind. Bitte beachten Sie, dass eine
      Nichterteilung der Einwilligung dazu führen kann, dass bestimmte Teile der
      Webseite möglicherweise nicht in vollem Umfang nutzbar sind. Ihre
      erteilten Einwilligungen bleiben gültig, bis Sie die entsprechenden
      Einstellungen in Ihrem Endgerät anpassen oder zurücksetzen.
    </p>

    <h4>
      Nachträgliche Datenverarbeitung durch Cookies und andere Technologien
    </h4>
    <p>
      Wir nutzen Technologien, die essentiell für die Nutzung spezifischer
      Funktionen unserer Webseite, wie beispielsweise die Warenkorbfunktion,
      unerlässlich sind. Diese Technologien erfassen und verarbeiten Ihre
      IP-Adresse, den Zeitpunkt Ihres Besuchs, Geräte- und Browser-Informationen
      sowie Details über Ihre Nutzung unserer Webseite (z. B. Informationen zum
      Inhalt des Warenkorbs). Dies geschieht im Rahmen einer Abwägung unserer
      überwiegenden berechtigten Interessen an der verbesserten Darstellung
      unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO .
    </p>
    <p>
      Darüber hinaus verwenden wir Technologien, um unseren rechtlichen
      Verpflichtungen nachzukommen, wie beispielsweise dem Nachweis von
      Einwilligungen zur Verarbeitung Ihrer personenbezogenen Daten, sowie für
      Webanalyse und Online-Marketing. Weitere detaillierte Informationen dazu,
      einschließlich der rechtlichen Grundlagen für die Datenverarbeitung,
      finden Sie in den nachfolgenden Abschnitten unserer Datenschutzerklärung.
    </p>
    <p>
      Falls Sie gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO in die Verwendung dieser
      Technologien eingewilligt haben, können Sie Ihre Einwilligung jederzeit
      widerrufen. Dies ist möglich durch eine Nachricht an die in unserer
      Datenschutzerklärung angegebene Kontaktmöglichkeit.
    </p>

    <h3>
      Nutzung von Cookies und weiteren Technologien für Webanalyse und Werbung
    </h3>
    <p>
      Sofern Sie gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre Einwilligung hierzu
      erteilt haben, setzen wir auf unserer Webseite die unten aufgeführten
      Cookies und andere Technologien von Drittanbietern ein. Nach Erreichen des
      Zwecks und Beendigung der Nutzung der jeweiligen Technologie durch uns
      werden die in diesem Zusammenhang erhobenen Daten gelöscht. Sie haben die
      Möglichkeit, Ihre Einwilligung jederzeit für die Zukunft zu widerrufen.
      Informationen dazu finden Sie im Abschnitt "Cookies und weitere
      Technologien". Detaillierte Angaben über die Grundlage unserer Kooperation
      mit den einzelnen Anbietern finden Sie bei den entsprechenden
      Technologien. Bei Fragen zu den Anbietern und den Bedingungen unserer
      Zusammenarbeit stehen wir Ihnen über die in dieser Datenschutzerklärung
      angegebenen Kontaktmöglichkeiten zur Verfügung.
    </p>

    <h4>Einsatz von Diensten zu Webanalyse und Werbezwecken</h4>
    <p>
      Wir nutzen die nachstehend aufgeführten Technologien der Google Ireland
      Ltd., Gordon House, Barrow Street, Dublin 4, Irland („Google“).
      Informationen über Ihre Nutzung unserer Webseite, die automatisch durch
      die Google-Technologien erfasst werden, werden in der Regel an einen
      Server der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
      USA, übertragen und dort gespeichert. Es gibt keine Entscheidung der
      Europäischen Kommission über die Angemessenheit des Datenschutzes in den
      USA. Unsere Zusammenarbeit mit ihnen basiert auf den
      Standard-Datenschutzklauseln der Europäischen Kommission. Sofern Ihre
      IP-Adresse über die Google-Technologien erhoben wird, erfolgt vor der
      Speicherung auf den Google-Servern eine Kürzung durch die Aktivierung der
      IP-Anonymisierung. Nur in Ausnahmefällen wird die volle IP-Adresse an
      einen Server von Google übertragen und dort gekürzt. Sofern nicht anders
      angegeben, erfolgt die Datenverarbeitung auf Grundlage einer Vereinbarung
      zwischen den gemeinsam Verantwortlichen gemäß Artikel 26 der
      Datenschutz-Grundverordnung (DSGVO) für jede spezifische Technologie.
      Weitere ausführliche Informationen zur Datenverarbeitung durch Google
      finden Sie in den Datenschutzhinweisen von Google.
    </p>
    <h4>Google Analytics</h4>
    <p>
      Zur Webseitenanalyse werden automatisch Daten (IP-Adresse,
      Besuchszeitpunkt, Geräte- und Browser-Informationen sowie Details zur
      Nutzung unserer Webseite) mittels Google Analytics erfasst und
      gespeichert. Anhand dieser Daten werden unter Verwendung von Pseudonymen
      Nutzungsprofile erstellt. Cookies können hierbei zum Einsatz kommen. Ihre
      IP-Adresse wird im Allgemeinen nicht mit anderen Daten von Google
      zusammengeführt. Die Datenverarbeitung basiert auf einer Vereinbarung zur
      Auftragsverarbeitung mit Google.
    </p>
    <h4>Sentry</h4>
    <p>
      Zur Sicherstellung der Stabilität und Sicherheit unserer Webseite setzen
      wir den Dienst Sentry ein. Dieser erfasst und speichert automatisch
      Informationen über Ihr Endgerät, Ihren Browser, Ihre IP-Adresse, den
      Zeitpunkt des Seitenaufrufs und die Dauer Ihres Besuchs. Diese Daten
      werden zur Analyse und Behebung von Fehlern verwendet. Die
      Datenverarbeitung erfolgt auf Grundlage einer Vereinbarung zur
      Auftragsverarbeitung mit Sentry.
    </p>
    <h4>Google Ads</h4>
    <p>
      Für Werbezwecke in den Google-Suchergebnissen und auf anderen Webseiten
      wird bei Ihrem Besuch auf unserer Webseite das Google Remarketing Cookie
      platziert. Dieses ermöglicht durch die automatische Erhebung und
      Verarbeitung von Daten (wie IP-Adresse, Besuchszeitpunkt, Geräte- und
      Browser-Informationen sowie Details zur Nutzung unserer Webseite) und
      anhand einer pseudonymen Cookie-ID eine personalisierte Werbung auf Basis
      der von Ihnen besuchten Seiten. Eine zusätzliche Datenverarbeitung erfolgt
      nur, wenn Sie die Option "personalisierte Werbung" in Ihrem Google-Konto
      aktiviert haben. In diesem Fall, wenn Sie während Ihres Besuchs unserer
      Webseite bei Google eingeloggt sind, verwendet Google Ihre Daten zusammen
      mit den Informationen von Google Analytics, um Zielgruppenlisten für
      geräteübergreifendes Remarketing zu erstellen und zu definieren. Für die
      Webseitenanalyse und Ereignisverfolgung messen wir Ihr nachfolgendes
      Nutzungsverhalten über das Google Ads Conversion Tracking, wenn Sie über
      eine Google Ads-Anzeige auf unsere Webseite gelangen. Hierbei können
      Cookies zum Einsatz kommen und Daten (wie IP-Adresse, Besuchszeitpunkt,
      Geräte- und Browser-Informationen sowie Informationen zur Nutzung unserer
      Webseite basierend auf von uns festgelegten Ereignissen wie dem Besuch
      einer bestimmten Seite oder der Anmeldung zum Newsletter) erfasst werden,
      um unter Verwendung von Pseudonymen Nutzungsprofile zu erstellen.
    </p>

    <h4>Google reCAPTCHA</h4>
    <p>
      Zum Schutz vor potenziellem Missbrauch unserer Web-Formulare und zur
      Vermeidung von Spam durch automatisierte Programme (sogenannte Bots)
      verwendet Google reCAPTCHA. Hierbei erfasst reCAPTCHA Daten wie
      IP-Adresse, Besuchszeitpunkt, Browser-Informationen sowie Details zur
      Nutzung unserer Webseite. Durch die Verwendung von JavaScript und Cookies
      führt reCAPTCHA eine Analyse Ihrer Interaktion mit unserer Webseite durch.
      Zudem werden andere Cookies, die von Google-Diensten in Ihrem Browser
      gespeichert sind, ausgewertet. Es erfolgt keine Erfassung oder Speicherung
      personenbezogener Daten aus den Eingabefeldern des jeweiligen Formulars.
    </p>

    <h3>Integration des Trusted Shops Trustbadge/ sonstiger Widgets</h3>
    <p>
      Zur Darstellung der Trusted Shops Leistungen wie beispielsweise Gütesiegel
      und gesammelte Bewertungen sowie zur Präsentation von Trusted Shops
      Produkten für Käufer nach einer Bestellung sind auf dieser Webseite
      Trusted Shops Widgets (zum Beispiel das Trusted Shops Trustbadge)
      integriert.
    </p>
    <p>
      Diese Integration erfolgt, um unsere überwiegenden berechtigten Interessen
      an einer optimalen Vermarktung zu wahren und einen sicheren Einkauf zu
      ermöglichen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Das Trustbadge und die
      beworbenen Dienste werden von Trusted Shops GmbH, Subbelrather Str. 15C,
      50823 Köln (Trusted Shops), bereitgestellt, mit der wir gemäß Art. 26
      DSGVO gemeinsam datenschutzrechtlich verantwortlich sind. Die wichtigsten
      Inhalte unserer Vereinbarung gemäß Art. 26 DSGVO werden in dieser
      Datenschutzerklärung erläutert. Bei Fragen zum Datenschutz und zur
      Ausübung Ihrer Rechte im Rahmen der gemeinsamen Verantwortlichkeit wenden
      Sie sich bitte vorrangig an Trusted Shops GmbH. Deren Kontaktdaten finden
      Sie hier. Weitere Informationen zum Datenschutz erhalten Sie über den
      folgenden Link hier. Unabhängig davon können Sie sich auch immer an uns
      über die in dieser Datenschutzerklärung angegebene Kontaktmöglichkeit
      wenden. Ihre Anfrage wird gegebenenfalls an den anderen Verantwortlichen
      weitergeleitet, um sie zu beantworten.
    </p>

    <h4>Datenverarbeitung bei Einbindung des Trustbadge/ sonstiger Widgets</h4>
    <p>
      Das Trustbadge wird als Teil einer gemeinsamen Verantwortlichkeit von
      einem US-amerikanischen CDN-Anbieter (Content-Delivery-Network)
      bereitgestellt. Ein angemessenes Datenschutzniveau wird durch
      Standarddatenschutzklauseln und weitere vertragliche Maßnahmen
      gewährleistet. Weitere Informationen zum Datenschutz der Trusted Shops
      GmbH finden Sie hier. Beim Aufruf des Trustbadges wird automatisch ein
      Server-Logfile vom Webserver gespeichert. Dieses Logfile enthält
      Zugriffsdaten wie Ihre IP-Adresse, Datum und Uhrzeit des Abrufs,
      übertragene Datenmenge und den anfragenden Provider. Die IP-Adresse wird
      unmittelbar nach der Erhebung anonymisiert, wodurch die gespeicherten
      Daten keiner bestimmten Person zugeordnet werden können. Die
      anonymisierten Daten dienen insbesondere statistischen Zwecken und der
      Fehleranalyse.
    </p>
    <h4>Datenverarbeitung nach Bestellabschluss</h4>
    <p>
      Nach dem Abschluss Ihrer Bestellung wird Ihre E-Mail-Adresse, die mittels
      kryptografischer Einwegfunktion gehasht wurde, an die Trusted Shops GmbH
      übermittelt. Die Rechtsgrundlage hierfür ergibt sich aus Art. 6 Abs. 1 S.
      1 lit. f DSGVO. Diese Maßnahme dient der Überprüfung, ob Sie bereits für
      Dienste bei Trusted Shops GmbH registriert sind. Es dient der Erfüllung
      unserer und der überwiegenden berechtigten Interessen von Trusted Shops an
      der Bereitstellung des Käuferschutzes und der transaktionalen
      Bewertungsleistungen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO, die an die
      konkrete Bestellung gekoppelt sind. Falls eine Registrierung bereits
      erfolgt ist, erfolgt die weitere Verarbeitung gemäß den zwischen Ihnen und
      Trusted Shops getroffenen vertraglichen Vereinbarungen. Wenn Sie noch
      nicht registriert sind, haben Sie die Möglichkeit, sich nachfolgend zu
      registrieren. Die weitere Verarbeitung nach der Registrierung erfolgt
      ebenfalls gemäß der vertraglichen Vereinbarung mit der Trusted Shops GmbH.
      Wenn Sie sich nicht registrieren, werden alle übermittelten Daten
      automatisch von Trusted Shops GmbH gelöscht, und eine Identifizierung ist
      dann nicht mehr möglich.
    </p>
    <p>
      Trusted Shops nutzt Dienstleister für Hosting, Monitoring und Logging. Die
      Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. f DSGVO, um einen
      reibungslosen Betrieb sicherzustellen. In diesem Zusammenhang kann eine
      Verarbeitung in Drittländern (USA und Israel) erfolgen. Ein angemessenes
      Datenschutzniveau wird im Falle der USA durch Standarddatenschutzklauseln
      und weitere vertragliche Maßnahmen sowie im Falle von Israel durch einen
      Angemessenheitsbeschluss sichergestellt.
    </p>
    <h3>Kontaktmöglichkeiten und Ihre Rechte</h3>
    <h4>Ihre Rechte</h4>
    <p>Als Betroffener haben Sie folgende Rechte:</p>
    <ul>
      <li>
        gemäß Art. 15 DSGVO das Recht, Auskunft über die von uns verarbeiteten
        personenbezogenen Daten zu verlangen.
      </li>
      <li>
        gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger
        oder die Vervollständigung Ihrer bei uns gespeicherten personenbezogenen
        Daten zu verlangen.
      </li>
      <li>
        gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten
        personenbezogenen Daten zu verlangen, es sei denn, die weitere
        Verarbeitung ist zur Ausübung des Rechts auf freie Meinungsäußerung und
        Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen
        des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen erforderlich.
      </li>
      <li>
        gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen, wenn die Richtigkeit der Daten von
        Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren
        Löschung ablehnen, wir die Daten nicht mehr benötigen, Sie diese jedoch
        zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
        benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die
        Verarbeitung eingelegt haben.
      </li>
      <li>
        gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns
        bereitgestellt haben, in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten oder die Übermittlung an einen
        anderen Verantwortlichen zu verlangen.
      </li>
      <li>
        gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu
        beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde
        Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseren
        Unternehmenssitz wenden.
      </li>
    </ul>
    <h4>Widerspruchsrecht</h4>
    <p>
      Sofern wir personenbezogene Daten zur Wahrung unserer überwiegenden
      berechtigten Interessen gemäß der bereits erläuterten Interessensabwägung
      verarbeiten, haben Sie das Recht, dieser Verarbeitung für die Zukunft zu
      widersprechen. Im Falle einer Verarbeitung für Direktmarketing-Zwecke
      können Sie dieses Widerspruchsrecht jederzeit gemäß den oben genannten
      Verfahren ausüben. Bei Verarbeitungen für andere Zwecke steht Ihnen das
      Widerspruchsrecht nur unter der Bedingung zu, dass spezielle Gründe aus
      Ihrer individuellen Situation resultieren. Nachdem Sie Ihr
      Widerspruchsrecht ausgeübt haben, werden wir Ihre personenbezogenen Daten
      nicht mehr für diese Zwecke verarbeiten, es sei denn, wir können zwingende
      schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
      Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der
      Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
      Diese Regelung findet keine Anwendung, wenn die Verarbeitung für
      Direktmarketing-Zwecke erfolgt. In diesem Fall werden wir Ihre
      personenbezogenen Daten nicht mehr für diesen Zweck verarbeiten.
    </p>
    <h4>Kontaktmöglichkeiten</h4>
    <p>
      Für jegliche Anfragen zur Erhebung, Verarbeitung oder Nutzung Ihrer
      persönlichen Daten, für Auskünfte, Berichtigungen, Einschränkungen oder
      Löschungen von Daten sowie für den Widerruf bereits erteilter
      Einwilligungen oder den Widerspruch gegen eine spezifische
      Datenverwendung, bitten wir Sie, sich direkt über die in unserem Impressum
      angegebenen Kontaktdaten an uns zu wenden.
    </p>
  </div>
</app-container>
