import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  viewChild,
} from '@angular/core';

@Component({
    selector: 'app-dialog',
    imports: [],
    templateUrl: './dialog.component.html',
    styleUrl: './dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent {
  elm = viewChild<ElementRef<HTMLDialogElement>>('dialog');

  autoShow = input<boolean>();
  preventClose = input<boolean>();

  constructor() {
    afterNextRender(() => {
      const elm = this.elm();
      if (elm) {
        elm.nativeElement.addEventListener('cancel', (event) => {
          if (this.preventClose()) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
          }
        });
      }

      if (this.autoShow()) {
        this.show();
      }
    });
  }

  show() {
    const elm = this.elm();
    if (elm) {
      elm.nativeElement.showModal();
    }
  }

  close() {
    const elm = this.elm();
    if (elm) {
      elm.nativeElement.close();
    }
  }
}
