<a
  [href]="externalLink()"
  [title]="linkTitle()"
  [target]="'_blank'"
  [ngClass]="{
    'bg-primary/20 text-primary': color() === 'light',
    'bg-primary text-white': color() === 'dark',
  }"
  class="w-full md:w-auto text-center inline-block rounded-md border border-transparent px-8 py-3 text-base font-medium hover:bg-primary hover:text-white"
  ><ng-content></ng-content
></a>
