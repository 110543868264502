import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { StaticAssetComponent } from '../components/static-asset/static-asset.component';
import { SectionHeaderComponent } from '../components/section-header/section-header.component';
import { SectionItemComponent } from '../components/section-item/section-item.component';
import { SectionItemsComponent } from '../components/section-items/section-items.component';
import { RouterLink } from '@angular/router';
import { CategoryService } from '../category.service';
import { CustomerStateService } from '../account/customer-state.service';

@Component({
  selector: 'app-sidebar',
  imports: [
    FaIconComponent,
    StaticAssetComponent,
    SectionHeaderComponent,
    SectionItemComponent,
    SectionItemsComponent,
    RouterLink,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.open]': 'opened()',
  },
})
export class SidebarComponent {
  opened = signal(false);
  closeIcon = faTimes;
  category = inject(CategoryService);
  customerState = inject(CustomerStateService);

  open() {
    this.opened.set(true);
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.opened.set(false);
    document.body.style.overflow = '';
  }
}
