import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { ProductCategoryPageComponent } from '../products/product-category-page/product-category-page.component';
import { ProductListComponent } from '../components/product-list/product-list.component';
import { HomeService } from '../home.service';
import { DOCUMENT } from '@angular/common';
import { updateCanonicalLink } from '../seo';
import { environment } from '../../environments/environment';
import { ContainerComponent } from '../components/container/container.component';
import { TabContainerComponent } from '../components/tab-container/tab-container.component';
import { StaticAssetComponent } from '../components/static-asset/static-asset.component';
import {
  FaqItem,
  FaqListComponent,
} from '../components/faq-list/faq-list.component';
import { MetaService } from '../meta.service';
import {
  VideoComponent,
  VideoSource,
} from '../components/video/video.component';
import { SecondaryLinkComponent } from '../components/secondary-link/secondary-link.component';
import { TeaserSectionComponent } from '../teaser-section/teaser-section.component';
import { TeaserImageComponent } from '../teaser-image/teaser-image.component';
import { TeaserTextComponent } from '../teaser-text/teaser-text.component';
import { ExternalLinkComponent } from '../components/external-link/external-link.component';
import { BenefitTeaserComponent } from '../benefit-teaser/benefit-teaser.component';

@Component({
  selector: 'app-home',
  imports: [
    ContainerComponent,
    RouterLink,
    ProductListComponent,
    StaticAssetComponent,
    FaqListComponent,
    VideoComponent,
    SecondaryLinkComponent,
    TeaserSectionComponent,
    TeaserImageComponent,
    TeaserTextComponent,
    ExternalLinkComponent,
    BenefitTeaserComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  productStore = inject(HomeService);
  meta = inject(MetaService);

  topPlants = this.productStore.topPlants;
  topHitPlants = this.productStore.topHitPlants;
  topPots = this.productStore.topPots;
  topAccessories = this.productStore.topAccessories;

  landingVideo: VideoSource[] = [
    {
      src: '/assets/video/intro.MP4',
      type: 'video/mp4',
    },
  ];

  faqs: FaqItem[] = [
    {
      id: 'zuhause',
      question: 'Welche Zimmerpflanzen sind für mein Zuhause geeignet?',
      answer:
        'Die Wahl der richtigen Pflanzen für dein Zuhause hängt von verschiedenen Faktoren ab, wie zum Beispiel Lichtverhältnisse, Temperatur, Luftfeuchtigkeit und deiner Fähigkeit, die Pflanzen zu pflegen. Benutze unsere Filterfunktion, um die passenden Pflanzen für dein Zuhause zu finden.',
    },
    {
      id: 'duengen',
      question: 'Muss ich meine Zimmerpflanzen düngen?',
      answer:
        'Ja, in den meisten Fällen profitieren Zimmerpflanzen von einer regelmäßigen Düngung. Düngen hilft, den Nährstoffgehalt im Boden zu ergänzen, da dieser mit der Zeit durch das Gießen und die Pflanzenaufnahme abnimmt.',
    },
    {
      id: 'giessen',
      question: 'Wie oft sollte ich meine Zimmerpflanzen gießen?',
      answer:
        'Die Häufigkeit des Gießens hängt von der Pflanzenart, dem Standort und der Jahreszeit ab. In der Regel sollten Zimmerpflanzen gegossen werden, wenn die oberste Erdschicht trocken ist. Vermeide es, deine Pflanzen zu übergießen, da dies zu Wurzelfäule führen kann.',
    },
  ];

  constructor(@Inject(DOCUMENT) dom: Document) {
    updateCanonicalLink(dom, environment.baseUrl);
    this.meta.setMetaTags(
      {
        keywords:
          'zimmerpflanzen, zimmerpflanzen kaufen, zimmerpflanzen zubehör kaufen, blumentöpfe',
        metaTitle:
          'Zimmerpflanzen direkt vom Gärtner | Pflegeleicht & Tierfreundlich',
        metaDescription:
          'Entdecken Sie eine große Auswahl an pflegeleichten Zimmerpflanzen, die wenig Licht benötigen. Kaufen Sie direkt vom Gärtner inklusive passendem Zubehör und Blumentöpfen. Finden Sie die richtige Pflanze für Ihr Zuhause, die tierfreundlich und einfach zu pflegen ist.',
      },
      undefined,
    );
  }
}
