<div class="bg-white pb-8">
  <app-container>
    <h1
      class="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl"
    >
      Dein Warenkorb ist leer
    </h1>
    <p class="text-base text-center leading-7 text-gray-600">
      Wähle zuerst deine Pflanzen und Zubehör
    </p>
    <app-static-asset
      [alt]="'not found, 404'"
      [extension]="'png'"
      [source]="'assets/static/404'"
      class="h-64"
    ></app-static-asset>

    <app-button-primary
      [action]="goToShop"
      class="mt-8"
      [appEvent]="'checkout'"
      [appEventData]="{ totalPrice: cartState().price.totalPrice }"
    >
      Zurück zum Shop
    </app-button-primary>
  </app-container>
</div>
