<app-header [header]="'AGB'"></app-header>

<app-container>
  <div class="prose py-8">
    <h2>Allgemeine Geschäftsbedingungen</h2>

    <h3>Geltungsbereich</h3>

    <p>
      Diese AGB regeln sämtliche Käufe, die über unseren Online-Shop von
      Verbrauchern sowie Unternehmern getätigt werden. Verbraucher sind
      Individuen, die Geschäfte tätigen, die nicht ihrer beruflichen oder
      gewerblichen Tätigkeit zuzuordnen sind. Unternehmer hingegen sind
      natürliche oder juristische Personen oder rechtsfähige Gesellschaften, die
      in Ausübung ihrer beruflichen oder gewerblichen Tätigkeit handeln. Bei
      Geschäften mit Unternehmern gelten diese Bedingungen auch für zukünftige
      Transaktionen ohne erneute Erwähnung. Abweichende oder ergänzende
      Geschäftsbedingungen eines Unternehmers erkennen wir nur an, wenn wir
      diesen ausdrücklich zustimmen.
    </p>

    <h3>Vertragspartner, Vertragsschluss, Korrekturmöglichkeiten</h3>
    <p>
      Der Kaufvertrag mit Tropico Trading GmbH, Lettenstrasse 6A, 6343 Rotkreuz,
      Schweiz abgeschlossen. Produkte, die wir im Online-Shop präsentieren,
      stellen ein Angebot dar. Sie können diese Produkte unverbindlich in den
      Warenkorb legen und Ihre Angaben vor dem verbindlichen Kauf korrigieren.
      Der Kauf wird durch das Klicken des Bestellbuttons abgeschlossen und per
      E-Mail bestätigt.
    </p>

    <h3>Vertragssprache und -text</h3>
    <p>
      Die Vertragssprache ist Deutsch. Der Text des Vertrags wird von uns nicht
      gespeichert.
    </p>

    <h3>Lieferbedingungen</h3>
    <p>
      Zu den Produktkosten kommen möglicherweise Versandgebühren hinzu, die in
      den Angeboten detailliert und vor dem Zahlungsvorgang ersichtlich sind.
      Die Lieferung erfolgt ausschliesslich per Versand, eine Abholung der Ware
      ist nicht möglich.
    </p>

    <h3>Zahlungsmodalitäten</h3>
    <p>
      In unserem Shop stehen Ihnen die folgenden Zahlungsarten zur Verfügung:
    </p>

    <h4>Kreditkarte</h4>
    <p>
      Ihre Karte wird bei Ihrer Abgabe der Bestellung belastet. Eine Bestellung
      wird erst nach erfolgreicher Zahlung ausgelöst.
    </p>

    <h3>Widerrufsrecht</h3>
    <p>
      Das gesetzliche Widerrufsrecht steht Verbrauchern zu und ist in der
      Widerrufsbelehrung detailliert beschrieben. Unternehmern wird kein
      freiwilliges Widerrufsrecht eingeräumt.
    </p>

    <h3>Eigentumsrecht</h3>
    <p>Die Ware bleibt bis zur vollständigen Bezahlung in unserem Besitz.</p>

    <h3>Transportschäden</h3>
    <p>
      Für Unternehmer gilt: Die Gefahr des zufälligen Untergangs und der
      zufälligen Verschlechterung geht auf Sie über, sobald wir die Sache dem
      Spediteur, dem Frachtführer oder der sonst zur Ausführung der Versendung
      bestimmten Person oder Anstalt ausgeliefert haben.
    </p>

    <h3>Gewährleistung und Garantien</h3>
    <p>
      Allgemein: Pflanzen sind ein lebendes Produkt, weshalb ihre Lebensdauer
      von ihrer Pflege abhängt. Daher kann der Unternehmer keine Garantie für
      die Lebensdauer geben.
    </p>

    <h4>Mängelhaftungsrecht</h4>
    <p>
      Soweit nicht nachstehend ausdrücklich anders vereinbart, gilt das
      gesetzliche Mängelhaftungsrecht. Die nachstehenden Einschränkungen und
      Fristverkürzungen gelten nicht für Ansprüche aufgrund von Schäden, die
      durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen
      verursacht wurden bei Verletzung des Lebens, des Körpers oder der
      Gesundheit bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung
      sowie Arglist bei Verletzung wesentlicher Vertragspflichten, deren
      Erfüllung die ordnungsgemässe Durchführung des Vertrages überhaupt erst
      ermöglicht und auf deren Einhaltung der Vertragspartner regelmässig
      vertrauen darf (Kardinalpflichten) im Rahmen eines Garantieversprechens,
      soweit vereinbart soweit der Anwendungsbereich des Produkthaftungsgesetzes
      eröffnet ist.
    </p>

    <h4>Beschränkungen gegenüber Unternehmern</h4>
    <p>
      Gegenüber Unternehmern gelten als Vereinbarung über die Beschaffenheit der
      Ware nur unsere eigenen Angaben und die Produktbeschreibungen des
      Herstellers, die in den Vertrag einbezogen wurden; für öffentliche
      Äusserungen des Herstellers oder sonstige Werbeaussagen übernehmen wir
      keine Haftung. Die gesetzlichen Verjährungsfristen für den
      Rückgriffsanspruch nach § 445a BGB bleiben unberührt.
    </p>

    <h4>Beschädigungen</h4>
    <p>
      Bei leichten Beschädigungen die über den Versandweg passieren übernehmen
      wir keine Haftung. Trotz maximalen Bemühungen unsererseits die Pflanzen in
      den Paket zu sichern, ist es in gewissen Fällen unvermeidlich leichte
      Beschädigungen zu 100% auszuschliessen. Wir übernehmen deshalb keine
      Haftung für leichte Beschädigungen. Es bleibt dabei, dass grundlegend der
      Versand von Pflanzen nie risikofrei ist. Schon beim auspacken alleine
      können einige Blätter oder Zweige brechen. Von kleinen Beschädigungen und
      Versandstress erholen sich die Pflanzen aber grundsätzlich sehr gut.
      Gewährleisten Sie den Pflanzen mehr Wurzelraum und befolgen Sie unsere
      Tipps damit diese neue Grundlage zur weiteren Entfaltung erhalten.
    </p>

    <h4>Schwerwiegende Beschädigungen</h4>
    <p>
      Bei schwerwiegenden Beschädigungen haftet der Versanddienstleister. Bei
      irreparablen Schäden ist von uns ein Schadenprotokoll auszufüllen.
      Wichtig: Bitte melden Sie schwere Beschädigungen mit Fotos von Pflanze und
      Verpackung unverzüglich innert 24h nach Entgegennahme an:
      <a href="mailto:info@zimmerpflanzen.de">{{ "info@zimmerpflanzen.de" }}</a>
      / nach Ablauf der 24h ist eine Haftung ausgeschlossen.
    </p>

    <h4>Beschädigter Zuchttopf</h4>
    <p>
      Ein Zuchttopf ist dazu gedacht, eine lebendige umgebung in der Gärtnerei
      zu schaffen. Auf lange Sicht wird dieser aber zu klein sein. Der Zuchttopf
      soll den Wurzelballen schützen und ihn bis zum Endverbraucher
      zusammenhalten. Dies funktioniert auch wenn ein Riss im Zuchttopf ist,
      daher ist ein Zuchttopf von der Garantie ausgeschlossen. Diese dünnen
      Kunststofftöpfe können während oder vor dem Versand brechen. Wir raten
      immer eine Pflanze nach Erhalt in einen Ziertopf umzutopfen, was dann auch
      den Zuchttopf überflüssig macht.
    </p>

    <h4>Kundendienst</h4>
    <p>
      Sie erreichen unseren Kundendienst für Fragen, Reklamationen und
      Beanstandungen werktags von 9:00 Uhr bis 17:00 Uhr per E-Mail unter
      <a href="mailto:info@zimmerpflanzen.de">{{ "info@zimmerpflanzen.de" }}</a>
      Im Falle einer Beschädigung oder Unzufriedenheit mit dem von Ihnen
      bestellten Produkt senden Sie uns per E-Mail oder über unser Ticketsystem
      ein Foto innerhalb von 7 Tagen nach Erhalt der Produkte. Dieses Foto
      sollte ein klares Bild des Problems vermitteln. Ansonsten können wir die
      Beanstandung nicht verifizieren.
    </p>

    <h4>Garantieausschlüsse</h4>
    <p>
      Pflanzen sind lebende Produkte, weshalb ihre Lebensdauer von der Pflege
      abhängt. Daher können wir keine Garantie für die Lebensdauer geben. Ein
      Zuchttopf ist dazu gedacht, eine passsende Umgebung in der Gärtnerei zu
      schaffen. Sie sind nicht für die langfristige Haltung von Pflanzen gedacht
      und bestehen oft aus sehr dünnem Plastik. Wir können auf diese Zuchttöpfe
      keine Garantie gewähren. Wir raten eine Pflanze zeitnahe in einen etwas
      grösseren Ziertopf umzutopfen, was nicht nur ästhetischer wirkt, sondern
      der Pflanze auch Platz gibt sich zu entfalten.
    </p>

    <h3>Haftung</h3>
    <p>
      Für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen
      Vertreter oder Erfüllungsgehilfen verursacht wurden, haften wir stets
      unbeschränkt bei Verletzung des Lebens, des Körpers oder der Gesundheit,
      bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung, bei
      Garantieversprechen, soweit vereinbart, oder insofern der
      Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist. Bei Verletzung
      wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemässe
      Durchführung des Vertrages überhaupt erst ermöglicht und auf deren
      Einhaltung der Vertragspartner regelmässig vertrauen darf,
      (Kardinalpflichten) durch leichte Fahrlässigkeit von uns, unseren
      gesetzlichen Vertretern oder Erfüllungsgehilfen ist die Haftung der Höhe
      nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit
      dessen Entstehung typischerweise gerechnet werden muss. Im Übrigen sind
      Ansprüche auf Schadensersatz ausgeschlossen.
    </p>

    <h3>Streitbeilegung</h3>
    <p>
      Die Europäische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit, die Sie hier finden. Zur Teilnahme an
      einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
      sind wir nicht verpflichtet und nicht bereit.
    </p>

    <h3>Schlussbestimmungen</h3>
    <p>
      Sind Sie Kaufmann im Sinne des Handelsgesetzbuches, juristische Person des
      öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist
      ausschliesslicher Gerichtsstand für alle Streitigkeiten aus
      Vertragsverhältnissen zwischen uns und Ihnen unser Geschäftssitz.
    </p>
  </div>
</app-container>
