import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ContainerComponent } from '../../components/container/container.component';
import { MetaService } from '../../meta.service';

@Component({
    selector: 'app-agb',
    imports: [HeaderComponent, ContainerComponent],
    templateUrl: './agb.component.html',
    styleUrl: './agb.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgbComponent {
  meta = inject(MetaService);

  constructor() {
    this.meta.setMetaTags(
      {
        keywords: 'zimmerpflanzen, agb',
        metaTitle: 'AGB',
        metaDescription:
          'Erfahren Sie alles Wichtige über unsere Allgemeinen Geschäftsbedingungen (AGB) für den Kauf von Zimmerpflanzen. Informieren Sie sich über Lieferbedingungen, Rückgaberecht und Haftungshinweise.',
      },
      undefined,
    );
  }
}
