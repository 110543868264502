<div
  class="grid gap-y-4 px-6 md:px-0 pb-4 grid-cols-[max-content_1fr_max-content_1fr_max-content]"
>
  <!--                      @if (showHeightPotProperties()) {-->
  <!--                        <div></div>-->
  <!--                        <app-property-item [label]="'Höhe'">-->
  <!--                          <app-property-->
  <!--                            [product]="plantState.element"-->
  <!--                            [type]="'height'"-->
  <!--                            [iconEnabled]="false"-->
  <!--                          ></app-property>-->
  <!--                        </app-property-item>-->
  <!--                        <div></div>-->
  <!--                        <app-property-item [label]="'Topfgrösse'">-->
  <!--                          <app-property-->
  <!--                            [product]="plantState.element"-->
  <!--                            [type]="'potsize'"-->
  <!--                            [iconEnabled]="false"-->
  <!--                          ></app-property>-->
  <!--                        </app-property-item>-->
  <!--                      }-->

  @if (showPlantProperties()) {
    <app-property-item [label]="'Licht'">
      <app-property-range
        [product]="product()"
        [type]="'light'"
      ></app-property-range>
    </app-property-item>
    <div></div>
    <app-property-item [label]="'Pflege'">
      <app-property-range
        [product]="product()"
        [type]="'skill'"
      ></app-property-range>
    </app-property-item>
    <div></div>
    <app-property-item [label]="'Wasserbedarf'">
      <app-property-range
        [product]="product()"
        [type]="'water'"
      ></app-property-range>
    </app-property-item>
  }
</div>
