import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-external-link',
  imports: [NgClass],
  templateUrl: './external-link.component.html',
  styleUrl: './external-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalLinkComponent {
  externalLink = input.required<string>();
  linkTitle = input.required<string>();
  color = input<'light' | 'dark'>('light');
}
