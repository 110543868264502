import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  signal,
} from '@angular/core';
import { ActionSheetStateService } from './action-sheet.state.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'app-action-sheet',
    imports: [FaIconComponent],
    templateUrl: './action-sheet.component.html',
    styleUrl: './action-sheet.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.max-h-0]': `!open()`,
        '[class.overflow-hidden]': `!open()`,
        '[class.max-h-infiniti]': `open()`,
    }
})
export class ActionSheetComponent {
  open = signal(false);
  state = inject(ActionSheetStateService);

  faTimes = faTimes;

  heading = input.required<string>();

  show() {
    this.open.set(true);
    this.state.setActive(this);
  }

  close() {
    this.open.set(false);
  }

  toggle() {
    this.open.update((s) => !s);
    if (this.open()) {
      this.state.setActive(this);
    }
  }
}
