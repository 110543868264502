import {
  ChangeDetectionStrategy,
  Component,
  input,
  model,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CartButtonComponent } from '../../components/cart-button/cart-button.component';
import { NgClass } from '@angular/common';
import { Schemas } from '../../../api-types/storeApiTypes';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-product-cart-section',
    imports: [FaIconComponent, CartButtonComponent, NgClass, FormsModule],
    templateUrl: './product-cart-section.component.html',
    styleUrl: './product-cart-section.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCartSectionComponent {
  product = input.required<Schemas['Product']>();
  quantity = model<number>(1);
  stepperValue = input<number>(1);
  upIcon = faAngleUp;
  downIcon = faAngleDown;

  down(val: number) {
    this.quantity.update((value) => value - val);
  }

  up(val: number) {
    this.quantity.update((value) => value + val);
  }

  update(val: string) {
    this.quantity.set(parseInt(val));
  }
}
