import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'app-property-item',
    imports: [],
    templateUrl: './property-item.component.html',
    styleUrl: './property-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyItemComponent {
  label = input.required<string>()
}
