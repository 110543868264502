@if (plantTips(); as plantTips) {
  @if (plantTips.length > 0) {
    <div class="pt-8 md:pt-0">
      <h2 class="text-2xl my-8 underline decoration-primary">
        Pflegetipps für die {{ product().name }}
      </h2>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-8">
        @for (plantTip of plantTips; track plantTip.title) {
          <div class="flex items-center gap-x-4">
            <app-static-asset
              [alt]="plantTip.title"
              [extension]="'png'"
              [extensions]="['png']"
              [source]="plantTip.icon"
              class="h-20 w-20 flex-shrink-0"
            ></app-static-asset>
            <div>
              <h4 class="font-bold mb-1 mt-4 text-gray-800">
                {{ plantTip.title }}
              </h4>
              <div
                [innerHTML]="plantTip.description"
                class="text-gray-700"
              ></div>
            </div>
          </div>
        }
      </div>
    </div>
  }
}
