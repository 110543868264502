import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
} from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { ProductListComponent } from '../../components/product-list/product-list.component';
import { ProductListService } from '../../product-list.service';
import { ProductFilterComponent } from '../product-filter/product-filter.component';
import { ProductCategoriesComponent } from '../product-categories/product-categories.component';
import { ProductCategoryHeaderComponent } from '../product-category-header/product-category-header.component';
import { ProductSortComponent } from '../product-sort/product-sort.component';
import { CategoryService } from '../../category.service';
import { MetaService } from '../../meta.service';
import { ProductListToolbarComponent } from '../product-list-toolbar/product-list-toolbar.component';
import { BenefitTeaserComponent } from '../../benefit-teaser/benefit-teaser.component';

@Component({
  selector: 'app-product-category-page',
  imports: [
    SearchBarComponent,
    ProductListComponent,
    ProductFilterComponent,
    ProductCategoriesComponent,
    ProductCategoryHeaderComponent,
    ProductSortComponent,
    ProductListToolbarComponent,
    BenefitTeaserComponent,
  ],
  templateUrl: './product-category-page.component.html',
  styleUrl: './product-category-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCategoryPageComponent {
  product = inject(ProductListService);
  category = inject(CategoryService);
  meta = inject(MetaService);

  store = this.product.productStore;

  constructor() {
    effect(() => {
      const selectedCategory = this.category.current();
      if (selectedCategory) {
        this.meta.setMetaTags(selectedCategory.meta, selectedCategory.seoUrl);
      }
    });
  }

  reset() {
    this.product.reset();
  }
}
