<div class="flex rounded-md shadow-sm">
  <div class="relative flex focus-within:z-10">
    <input
      [type]="'number'"
      aria-label="Warenkorbmenge"
      [ngModel]="quantity()"
      (ngModelChange)="update($event)"
      [ngClass]="{
        'rounded-md': product().stock <= 0,
        'rounded-l-md rounded-none': product().stock > 0,
      }"
      class="block w-12 disabled:bg-gray-100 border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
    />
  </div>
  @if (stepperValue(); as stepperValue) {
    <button
      (click)="down(stepperValue)"
      type="button"
      aria-label="Warenkorbmenge reduzieren"
      class="relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      <fa-icon [icon]="downIcon"></fa-icon>
    </button>
    <button
      (click)="up(stepperValue)"
      type="button"
      aria-label="Warenkorbmenge erhöhen"
      class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      <fa-icon [icon]="upIcon"></fa-icon>
    </button>
  }
</div>

<app-cart-button
  class="flex-grow min-w-0"
  [location]="'add-to-cart'"
  [plant]="product()"
  [quantity]="quantity()"
></app-cart-button>
