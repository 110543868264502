import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ContainerComponent } from '../../components/container/container.component';
import { MetaService } from '../../meta.service';

@Component({
    selector: 'app-impressum',
    imports: [HeaderComponent, ContainerComponent],
    templateUrl: './impressum.component.html',
    styleUrl: './impressum.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpressumComponent {
  meta = inject(MetaService);

  constructor() {
    this.meta.setMetaTags(
      {
        keywords: 'zimmerpflanzen, impressum',
        metaTitle: 'Impressum',
        metaDescription:
          'Entdecken Sie unser Impressum, um wichtige rechtliche Informationen zu zimmerpflanzen.de, Ihrem vertrauenswürdigen Online-Shop für Zimmerpflanzen, zu erhalten. Kontaktdaten und rechtliche Hinweise auf einen Blick.',
      },
      undefined,
    );
  }
}
