import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  model,
  output,
} from '@angular/core';
import { Sort } from '../../sort';
import { ProductListService } from '../../product-list.service';
import { CollapsableOptionGroupComponent } from '../../components/collapsable-option-group/collapsable-option-group.component';
import { CollapsableOptionComponent } from '../../components/collapsable-option/collapsable-option.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CollapsableOptionHeaderComponent } from '../../components/collapsable-option-header/collapsable-option-header.component';

@Component({
  selector: 'app-product-sort',
  imports: [
    CollapsableOptionGroupComponent,
    CollapsableOptionComponent,
    CollapsableOptionHeaderComponent,
  ],
  templateUrl: './product-sort.component.html',
  styleUrl: './product-sort.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSortComponent {
  products = inject(ProductListService);
  router = inject(Router);
  route = inject(ActivatedRoute);

  open = model<boolean>(false);
  collapsable = input<boolean>(false);
  selected = output<Sort>();
  showLabel = input<boolean>(false);

  async selectSort(sort: Sort) {
    await this.router.navigate(['.'], {
      queryParams: { sort: sort.label },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
    this.selected.emit(sort);
  }
}
