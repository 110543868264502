export function updateCanonicalLink(dom: Document, url: string) {
  const head = dom.getElementsByTagName('head')[0];
  let element: HTMLLinkElement | null =
    dom.querySelector(`link[rel='canonical']`) || null;
  if (element == null) {
    element = dom.createElement('link') as HTMLLinkElement;
    head.appendChild(element);
  }
  element.setAttribute('rel', 'canonical');
  element.setAttribute('href', url);
}
