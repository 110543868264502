import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { CategoryService } from '../../category.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { StaticAssetComponent } from '../../components/static-asset/static-asset.component';
import { RegistrationFormComponent } from '../registration-form/registration-form.component';

@Component({
  selector: 'app-signup-sidebar',
  imports: [FaIconComponent, StaticAssetComponent, RegistrationFormComponent],
  templateUrl: './signup-sidebar.component.html',
  styleUrl: './signup-sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.open]': 'opened()',
  },
})
export class SignupSidebarComponent {
  opened = signal(false);
  closeIcon = faTimes;
  category = inject(CategoryService);

  open() {
    this.opened.set(true);
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.opened.set(false);
    document.body.style.overflow = '';
  }
}
