<div class="flex space-x-2 mt-1">
  @for (index of indices; track $index) {
    <div
      class="rounded-full h-4 w-4 border-2 border-primary"
      [ngClass]="{
        'bg-primary': index <= value(),
        'bg-primary-light': index > value() && index + 1 <= value(),
        'bg-white': index > value(),
      }"
    ></div>
  }
</div>
