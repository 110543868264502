<app-header [header]="'Über uns'"></app-header>

<app-container>
  <div class="grid grid-cols-1 gap-x-6 md:grid-cols-2">
    <app-section-image
      [header]="'Die Geschwister Imhof mit Scooby Doo'"
      [image]="'/assets/static/imhofs'"
    >
      Langjährige Zusammenarbeit und Wissensaustausch mit qualitativen Züchtern
      ermöglichen uns die aktuellsten Pflanzen direkt aus den Kulturen der
      jeweiligen Gärtner anbieten zu können. Als Pflanzenenthusiasten teilen wir
      eine tiefe Leidenschaft für die Gartenarbeit und das Grün, das unser Leben
      bereichert. Über die Jahre hinweg haben wir ein umfangreiches Wissen und
      eine enge Verbindung zur Natur entwickelt, die wir gerne mit Ihnen teilen
      möchten. Wir betrachten es als unsere Mission, die Schönheit und Vielfalt
      der Pflanzenwelt direkt zu Ihnen nach Hause zu bringen. Wir arbeiten dafür
      Hand in Hand mit einigen der besten Züchter, um sicherzustellen, dass wir
      Ihnen ausschließlich Pflanzen von höchster Qualität anbieten.
    </app-section-image>

    <app-section-image
      [header]="'Pascal der Techniker'"
      [image]="'/assets/static/pascal'"
    >
      Pascal ist ein hochqualifizierter IT-Experte, dessen Fachkenntnisse und
      Erfahrungen ihn zu einem unverzichtbaren Bestandteil unseres Teams gemacht
      haben, insbesondere im Bereich der Entwicklung und Wartung unseres
      Webshops. Er ist derjenige, der hinter den Kulissen sicherstellt, dass
      unser Online-Handel reibungslos funktioniert. Zu seinen Aufgaben gehört
      die kontinuierliche Weiterentwicklung unseres Webshops. Pascal achtet
      darauf, dass die Website nicht nur optisch ansprechend, sondern auch
      intuitiv zu bedienen ist, sodass unsere Kunden ein angenehmes
      Einkaufserlebnis genießen können.
    </app-section-image>
  </div>
</app-container>
