import { computed, inject, Injectable, makeStateKey } from '@angular/core';
import { Schemas } from '../api-types/storeApiTypes';
import { StoreApiService } from './store-api.service';
import {
  AppliedQuery,
  FilteredListState,
  FilterState,
} from './filteredListStore';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import {
  productAggregations,
  productAssociations,
} from './productAssociations';
import { Sort } from './sort';
import { ActivatedRoute, Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { CategoryService } from './category.service';

export interface PlantFilter extends FilterState {
  price: number;
  text: string;
  sort: Sort;
  category: string | null;
}

function isFilter(val: string) {
  return val.indexOf(':') > 0;
}

function splitQueryValue(val: string) {
  const parts = val.split(':');
  return {
    label: parts[0],
    id: parts[1],
  };
}

@Injectable({
  providedIn: 'root',
})
export class ProductListService {
  private readonly cookie = inject(SsrCookieService);
  private readonly store = inject(StoreApiService);
  private readonly route = inject(ActivatedRoute);
  private readonly category = inject(CategoryService);
  private readonly router = inject(Router);

  sortOptions: Sort[] = [
    {
      label: 'Neuste',
      order: 'DESC',
      field: 'releaseDate',
    },
    {
      label: 'Liefertermin',
      order: 'DESC',
      field: 'stock',
    },
    {
      label: 'Preis aufsteigend',
      order: 'ASC',
      field: 'price',
    },
    {
      label: 'Preis absteigend',
      order: 'DESC',
      field: 'price',
    },
  ];

  queryParams = toSignal(this.route.queryParams);

  readonly queryFilter = computed<PlantFilter>(() => {
    const query = this.queryParams();
    const category = this.category.current();

    const initial: PlantFilter = {
      price: 0,
      text: '',
      appliedQuery: [],
      category: null,
      sort: { field: 'releaseDate', order: 'DESC', label: 'Neuste' },
    };

    if (category) {
      initial.category = category.id;
    }

    const filterMap: Record<string, AppliedQuery> = {};

    if (query) {
      for (const [key, value] of Object.entries(query)) {
        if (key === 'search') {
          initial.text = value;
        } else if (key === 'sort') {
          const sort = this.sortOptions.find((s) => s.label === query['sort']);
          if (sort) {
            initial.sort = sort;
          }
        } else {
          if (!filterMap[key]) {
            if (value instanceof Array) {
              if (value.filter(isFilter).length > 0) {
                filterMap[key] = {
                  type: key,
                  values: value.filter(isFilter).map(splitQueryValue),
                };
              }
            } else if (isFilter(value)) {
              filterMap[key] = { type: key, values: [splitQueryValue(value)] };
            }
          } else {
            if (value instanceof Array) {
              filterMap[key].values.push(
                ...value.filter(isFilter).map(splitQueryValue),
              );
            } else if (isFilter(value)) {
              filterMap[key].values.push(splitQueryValue(value));
            }
          }
        }
      }
    }

    initial.appliedQuery = Object.values(filterMap);

    return initial;
  });

  reset() {
    this.router.navigate(['/Zimmerpflanzen']);
  }

  readonly productStore = this.store.createFilteredStore(
    makeStateKey<FilteredListState<PlantFilter, Schemas['Product']>>(
      'product-list',
    ),
    this.queryFilter,
    (filter, skip, signal) => {
      const conditions: Schemas['Filters'] = [];
      const postConditions: Schemas['Filters'] = [];

      if (filter.category) {
        conditions.push({
          field: 'categories.id',
          value: filter.category,
          type: 'equals',
        });
      }

      for (const appliedQuery of filter.appliedQuery) {
        for (const value of appliedQuery.values) {
          conditions.push({
            field: 'propertyIds',
            type: 'contains',
            value: value.id,
          });
        }
      }

      if (filter.price > 0) {
        conditions.push({
          type: 'range',
          field: 'price',
          parameters: {
            lte: filter.price,
          },
        });
      }

      const limit = 40;
      return this.store.getProducts(
        {
          limit: limit,
          aggregations: productAggregations,
          associations: productAssociations,
          term: filter.text,
          page: skip / limit + 1,
          'total-count-mode': 'exact',
          filter: conditions,
          'post-filter': postConditions,
          sort: [
            { field: 'stock', order: 'DESC' },
            filter.sort,
            { field: 'name', order: 'ASC' },
          ],
        },
        signal,
      );
    },
  );
}
