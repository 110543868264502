<div class="flex flex-col md:flex-row-reverse px-2 relative overflow-hidden">
  <div
    class="overflow-x-auto md:overflow-x-hidden hide-scrollbar snap-mandatory md:scroll-auto snap-x flex w-full h-[50dvh] md:h-desktop-media"
    #scrollContainer
    (scroll)="onScroll($event)"
  >
    @for (media of sortedMedia(); let first = $first; track media.id) {
      <div class="snap-center w-full flex flex-shrink-0 flex-col">
        @defer (on viewport) {
          <app-media-image
            [fetchpriority]="'high'"
            [size]="'extra-large'"
            [imageLoading]="first ? 'eager' : 'lazy'"
            [objectFit]="'object-contain'"
            class="h-full"
            [image]="media"
            [alt]="media.alt ?? fallbackAlt()"
          ></app-media-image>
        } @placeholder {
          <div class="h-full w-full"></div>
        }
      </div>
    }
  </div>

  <div
    class="flex md:justify-end items-center justify-center py-2 md:flex-col gap-x-2 gap-y-2"
  >
    @for (media of sortedMedia(); let first = $first; track media.id) {
      <button
        (click)="goto($index, scrollContainer)"
        class="snap-center md:w-20 md:h-20 w-14 h-14 bg-white flex flex-shrink-0 flex-col rounded border"
        [appEvent]="'select-img'"
        [appEventData]="{ id: media.id, index: $index }"
        [ngClass]="{
          'border-primary': index() === $index,
          'border-gray-200': index() !== $index,
        }"
      >
        <app-media-image
          [size]="'small'"
          class="h-full"
          [imageLoading]="'eager'"
          [objectFit]="'object-contain'"
          [image]="media"
          [alt]="media.alt ?? fallbackAlt()"
        ></app-media-image>
      </button>
    }
  </div>
</div>
