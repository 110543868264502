<app-benefit-teaser></app-benefit-teaser>
<div class="bg-primary">
  <app-container>
    <div class="relative flex-col items-center text-center py-24 lg:px-0">
      <!--      <app-content-flip></app-content-flip>-->
      <app-static-asset
        [alt]="'zimmerpflanzen.de brand logo'"
        [extension]="'png'"
        [loading]="'eager'"
        class="h-60"
        [source]="'/assets/static/logo-500'"
      ></app-static-asset>

      <p class="text-4xl mt-8 font-bold tracking-tight text-white lg:text-6xl">
        Zimmerpflanzen
      </p>
      <h1 class="mt-4 text-xl text-white">
        Jetzt direkt vom Gärtner Zimmerpflanzen kaufen für ein gesundes
        Raumklima. Bestelle bei uns bequem online deine Zimmerpflanzen und
        ergänze damit deine grüne Oase zu Hause.
      </h1>
      <a
        [routerLink]="'/Zimmerpflanzen'"
        class="mt-8 inline-block rounded-md border border-transparent bg-primary-light text-primary-orange-contract px-8 py-3 text-base font-medium hover:bg-primary-orange/80"
        >Zum Shop</a
      >
    </div>
  </app-container>
</div>

<!--<div-->
<!--  class="bg-primary-orange font-bold flex flex-col md:flex-row items-center justify-center"-->
<!--&gt;-->
<!--  <div-->
<!--    class="pt-4 md:py-4 pl-4 flex items-center justify-center text-lg md:text-2xl"-->
<!--  >-->
<!--    Sommer Sale endet bald-->
<!--  </div>-->
<!--  <app-countdown class="mx-8 py-4"></app-countdown>-->
<!--  <div-->
<!--    class="pb-4 md:py-4 pr-4 flex items-center justify-center text-lg md:text-2xl"-->
<!--  >-->
<!--    Spare bis zu 20%-->
<!--  </div>-->
<!--</div>-->

<app-video
  [sources]="landingVideo"
  class="h-dvh md:hidden"
  [poster]="'/assets/static/intro-poster.jpg'"
></app-video>

<div class="bg-primary/20">
  <app-container>
    <div
      class="py-4 flex flex-col md:flex-row md:items-center md:justify-between"
    >
      <p class="text-lg leading-8 text-primary">
        Folge uns auf Instagram und erhalte exklusive Angebote, Inspirationen
        und verpasse keine Neuigkeiten.
      </p>
      <app-external-link
        [color]="'dark'"
        class="mt-4 md:mt-0"
        [linkTitle]="'Instagram'"
        [externalLink]="'https://www.instagram.com/zimmerpflanzen.de/'"
      >
        Unser Instagram
      </app-external-link>
    </div>
  </app-container>
</div>

<app-container class="py-24" id="sonderangebote">
  <div class="flex flex-col space-y-8">
    <section class="">
      <h2 class="text-2xl font-bold tracking-tight text-gray-900">
        Sonderangebote
      </h2>
      <p class="mt-4 text-lg leading-8 text-gray-600">
        Limitierte Angebote und Sonderpreise für Zimmerpflanzen, nur für kurze
        Zeit.
      </p>

      <div class="mt-10 flex flex-col items-center">
        <app-product-list
          [store]="topHitPlants()"
          [backlink]="'/'"
          [canReset]="false"
          class="w-full"
        ></app-product-list>
      </div>
    </section>
  </div>
</app-container>

<div class="py-16 bg-primary/10">
  <app-teaser-section>
    <app-teaser-image>
      <app-static-asset
        class="pt-12 px-6"
        [alt]="'zimmerpflanzen geniesser'"
        [extension]="'png'"
        [loading]="'eager'"
        [source]="'/assets/static/figure-1'"
      ></app-static-asset>
    </app-teaser-image>
    <app-teaser-text
      [teaser]="'Entspannung durch Grün'"
      [heading]="'Richte Dir deine Oase so ein, wie du sie möchtest'"
      [text]="
        'Schau dich um und bestell was zu dir passt oder dir gefällt. Schliesse die Bestellung ab und lehn dich zurück. In wenigen Tagen Kommt deine Bestellung mit deinen neuen Zimmerpflanzen zu dir nach Hause. Kein schweres Schleppen - Kein Aufwand!'
      "
    >
    </app-teaser-text>
  </app-teaser-section>
</div>

<app-container class="py-24">
  <div class="flex flex-col space-y-8">
    <section class="">
      <h2 class="text-2xl font-bold tracking-tight text-gray-900">
        Zimmerpflanzen
      </h2>
      <p class="mt-4 text-lg leading-8 text-gray-600">
        Warum deine Zimmerpflanzen bei uns kaufen? Dank Zusammenarbeit mit
        ausgewählten Züchtern bieten wir wöchentlich neue Angebote und schöne
        Zimmerpflanzen an. Vielfalt und Aktualität stehen bei uns im Fokus.
      </p>

      <div class="mt-10 flex flex-col items-center">
        <app-product-list
          [store]="topPlants()"
          [backlink]="'/'"
          [canReset]="false"
          class="w-full"
        ></app-product-list>

        <app-secondary-link
          class="mt-8"
          [link]="'/Zimmerpflanzen'"
          [linkTitle]="'Zimmerpflanzen'"
        >
          Weitere Zimmerpflanzen
        </app-secondary-link>
      </div>
    </section>
  </div>
</app-container>

<div class="py-16 bg-primary/10">
  <app-teaser-section>
    <app-teaser-text
      [teaser]="'Das gewisse etwas'"
      [heading]="'Der passende Topf für deine Pflanze'"
      [text]="
        'Der richtige Topf für deine Pflanze ist wichtig. Das verleiht deiner Pflanze nicht nur mehr Eleganz, sondern auch mehr Lebensraum. Wir bieten dir eine Auswahl an schönen Blumentöpfen.'
      "
    >
    </app-teaser-text>
    <app-teaser-image>
      <app-static-asset
        class="pt-12 px-6"
        [alt]="'zimmerpflanzen töpfe'"
        [extension]="'png'"
        [loading]="'eager'"
        [source]="'/assets/static/figure-2'"
      ></app-static-asset>
    </app-teaser-image>
  </app-teaser-section>
</div>

<app-container class="py-24">
  <div class="flex flex-col space-y-8">
    <section class="">
      <h2 class="text-2xl font-bold tracking-tight text-gray-900">
        Blumentöpfe
      </h2>
      <p class="mt-4 text-lg leading-8 text-gray-600">
        Nicht nur Zimmerpflanzen kaufen kannst du bei uns, sondern auch gleich
        den richtigen Blumentopf. Bestelle bequem online deine Blumentöpfe und
        verleihe deinen Zimmerpflanzen mehr Eleganz und Raum.
      </p>

      <div class="mt-10 flex flex-col items-center">
        <app-product-list
          [store]="topPots()"
          [backlink]="'/'"
          [canReset]="false"
          class="w-full"
        ></app-product-list>

        <app-secondary-link
          class="mt-8"
          [link]="'/Blumentoepfe'"
          [linkTitle]="'Blumentoepfe'"
        >
          Weitere Blumentöpfe
        </app-secondary-link>
      </div>
    </section>
  </div>
</app-container>

<div class="py-16 bg-primary/10">
  <app-teaser-section>
    <app-teaser-image>
      <app-static-asset
        class="pt-12 px-6"
        [alt]="'zimmerpflanzen pflege'"
        [extension]="'png'"
        [loading]="'eager'"
        [source]="'/assets/static/figure-5'"
      ></app-static-asset>
    </app-teaser-image>
    <app-teaser-text
      [teaser]="'Pflegeleicht und schön'"
      [heading]="'Hier findest du alles zur Pflege'"
      [text]="
        'Wir bieten dir nicht nur Zimmerpflanzen, sondern auch das passende Zubehör und Pflegeprodukte. Entdecke unser Sortiment und bestelle bequem online. Zu jeder Pflanze findest du bei uns auch die passende Pflegeanleitung.'
      "
    >
      <app-external-link
        class="mt-2"
        [externalLink]="'/blog'"
        [linkTitle]="'Zimmerpflanzen Blog'"
        >Zum Blog</app-external-link
      >
    </app-teaser-text>
  </app-teaser-section>
</div>

<app-container class="py-24">
  <div class="flex flex-col space-y-8">
    <section>
      <h2 class="text-2xl font-bold tracking-tight text-gray-900">Zubehör</h2>
      <p class="mt-4 text-lg leading-8 text-gray-600">
        Entdecke bei uns auch hochwertige Substrate und nützliches Zubehör für
        deine Zimmerpflanzen.
      </p>

      <div class="mt-10 flex flex-col items-center">
        <app-product-list
          [backlink]="'/'"
          [canReset]="false"
          [store]="topAccessories()"
          class="w-full"
        ></app-product-list>

        <app-secondary-link
          class="mt-8"
          [link]="'/Zubehoer'"
          [linkTitle]="'Zubehör für Zimmerpflanzen'"
        >
          Weiteres Zubehör
        </app-secondary-link>
      </div>
    </section>
  </div>
</app-container>

<div class="bg-white pb-16 md:py-16">
  <app-teaser-section>
    <app-teaser-image>
      <app-static-asset
        [alt]="'zimmerpflanzen über uns'"
        [extension]="'jpg'"
        [loading]="'lazy'"
        [source]="'/assets/static/jo_und_pascal'"
      ></app-static-asset>
    </app-teaser-image>
    <app-teaser-text
      [teaser]="'Über uns'"
      [heading]="'Wer wir sind?'"
      [text]="
        'Wir sind Jo und Pascal, zwei Zimmerpflanzenliebhaber. Wir haben es uns zur Aufgabe gemacht, dir die schönsten Zimmerpflanzen und das passende Zubehör zu bieten. Wir arbeiten mit ausgewählten Züchtern zusammen und bieten dir wöchentlich neue Angebote.'
      "
    >
    </app-teaser-text>
  </app-teaser-section>
</div>

<!--<div class="bg-black">-->
<!--  <app-container>-->
<!--    <div class="w-full aspect-[9/16] md:max-h-[60vh] relative">-->
<!--      <iframe-->
<!--        src="https://player.vimeo.com/video/979350375?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"-->
<!--        frameborder="0"-->
<!--        allow="autoplay;"-->
<!--        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"-->
<!--        title="Zimmerpflanzen"-->
<!--      ></iframe>-->
<!--    </div>-->
<!--  </app-container>-->
<!--</div>-->

<div class="bg-primary/10 py-24 sm:py-32">
  <app-container>
    <section>
      <app-faq-list [items]="faqs"> </app-faq-list>
    </section>
  </app-container>
</div>
