import {
  computed,
  inject,
  Injectable,
  makeStateKey,
  signal,
} from '@angular/core';
import { StoreApiService } from './store-api.service';
import { ListState } from './listStore';
import { Schemas } from '../api-types/storeApiTypes';
import { MetaInfo } from './meta.service';

export interface Category {
  id: string;
  parentId: string | null;
  name: string;
  position: number;
  description: string;
  breadcrumbs: string[];
  level: number;
  url: string;
  meta: MetaInfo;
  seoUrl: Schemas['SeoUrl'] | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  storeApi = inject(StoreApiService);

  store = this.storeApi.createApiStore(
    makeStateKey<ListState<Category>>('categories'),
    () =>
      this.storeApi.apiClient
        .invoke('readCategoryList post /category', {
          body: {
            associations: {
              seoUrls: {},
            },
          },
        })
        .then((r) => ({
          elements: (r.data.elements as Schemas['Category'][])
            .filter((e) => e.active)
            .map<Category>((c) => ({
              id: c.id,
              description: (c.description ?? '').replace(/<[^>]*>/g, ''),
              parentId: c.parentId ?? null,
              name: c.translated.name ?? c.name,
              breadcrumbs: c.translated.breadcrumb,
              level: c.level ?? 0,
              url: c.seoUrls ? '/' + c.seoUrls[0]?.seoPathInfo : '',
              seoUrl: c.seoUrls ? c.seoUrls[0] : undefined,
              meta: c.translated,
              position: this.getPosition(
                c,
                r.data.elements as Schemas['Category'][],
              ),
            })),
          total: r.data.total ?? 0,
        })),
  );

  private getPosition(
    category: Schemas['Category'],
    categories: Schemas['Category'][],
  ): number {
    if (!category.afterCategoryId) {
      return 0;
    }
    const afterCategory = categories.find(
      (c) => c.id === category.afterCategoryId,
    );
    if (!afterCategory) {
      return 0;
    }
    return this.getPosition(afterCategory, categories) + 1;
  }

  current = signal<Category | null>(null);

  categories = computed<Category[]>(() => {
    const state = this.store();
    if (state.loading) {
      return [];
    }
    return state.elements;
  });

  mainCategories = computed(() => {
    return this.categories()
      .filter((c) => c.level === 2)
      .sort((f, s) => f.position - s.position);
  });

  subCategories = computed(() => {
    return this.categories().filter((c) => c.parentId === this.current()?.id);
  });
}
