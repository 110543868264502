import { Signal, signal } from '@angular/core';
import { DetailStore } from './detailStore';

export interface FilterStore<T> {
  set: (state: T) => void;
  update: (fn: (state: T) => T) => void;
  reset: () => void;
  state: Signal<T>;
}

export function relatedFilterStore<T, S>(
  store: DetailStore<S>,
  initValue: T,
  update: (store: S | null, filter: T) => T,
): FilterStore<T> {
  const filterStore = signal<T>(initValue);

  store.onChange((elm) => {
    filterStore.update((filter) => {
      const newValue = update(elm, filter);
      return { ...newValue };
    });
  });

  return {
    state: filterStore,
    reset: () => {
      filterStore.set(initValue);
    },
    update: (fn) => {
      filterStore.update(fn);
    },
    set: (state: T) => {
      filterStore.set(state);
    },
  };
}
