@if (category.current(); as category) {
  @if (category.description) {
    <div
      class="flex flex-col rounded-md bg-white border text-primary border-primary/30"
    >
      <app-container>
        <div class="w-full py-3 md:py-12">
          <h1 class="text-3xl mb-1">{{ category.name }}</h1>

          <p>{{ category.description }}</p>
        </div>
      </app-container>
    </div>
  }
}
