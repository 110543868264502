import {
  ChangeDetectionStrategy,
  Component,
  inject,
  viewChild,
} from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { EventService } from './event.service';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ViewportScroller } from '@angular/common';
import { SignupSidebarComponent } from './account/signup-sidebar/signup-sidebar.component';
import { CustomerStateService } from './account/customer-state.service';
//import { getInjectedMlink } from '@triarc-labs/mlink-embedded';
@Component({
  selector: 'app-root',
  imports: [
    RouterOutlet,
    NavbarComponent,
    FooterComponent,
    CookieBannerComponent,
    CookieBannerComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    SignupSidebarComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  router = inject(Router);
  event = inject(EventService);
  viewport = inject(ViewportScroller);
  signupSidebar = viewChild(SignupSidebarComponent);
  customerState = inject(CustomerStateService);

  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.event.push('navigation', { url: event.url });
      }
    });
    this.viewport.setOffset([0, 64]);

    this.customerState.setSidebar(this.signupSidebar);
    // afterNextRender(() => {
    //   getInjectedMlink()?.createEmbeddedAssistant(document.body, {
    //     assistantId: 'jakob',
    //     baseUrl: 'https://mlink.ortho.devcloud.triarc-labs.com',
    //   });
    // });
  }
}
