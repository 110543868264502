@if (link(); as link) {
  <a
    itemprop="item"
    [routerLink]="link"
    [title]="label()"
    class="text-left py-2 rounded-md px-3 w-full mb-2 flex justify-between"
    (click)="emit()"
    [ngClass]="{
      'bg-primary text-white active': active(),
      'bg-gray-200': !active(),
    }"
  >
    <span itemprop="name">{{ label() }}</span>
    <ng-content></ng-content>
  </a>
} @else {
  <button
    itemprop="item"
    class="text-left py-2 rounded-md px-3 w-full mb-2 flex justify-between"
    (click)="emit()"
    [ngClass]="{
      'bg-primary text-white active': active(),
      'bg-gray-200': !active(),
    }"
  >
    <span itemprop="name">{{ label() }}</span>
    <ng-content></ng-content>
  </button>
}
