import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons';
import { EventService } from '../../event.service';
import { ProductListService } from '../../product-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  imports: [FaIconComponent],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent {
  products = inject(ProductListService);
  event = inject(EventService);
  router = inject(Router);
  route = inject(ActivatedRoute);

  private searchSubject = new Subject<string>();

  protected readonly faSearch = faSearch;

  constructor() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((text) => {
      this.router.navigate(['.'], {
        queryParams: { search: text },
        queryParamsHandling: 'merge',
        relativeTo: this.route,
      });
      this.event.push('search', { text });
    });
  }

  async updateSearch(evt: Event) {
    const text = (evt.target as HTMLInputElement).value;
    this.searchSubject.next(text);
  }
}
