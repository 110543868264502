import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ContainerComponent } from '../../components/container/container.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { SectionItemComponent } from '../../components/section-item/section-item.component';
import { SectionHeaderComponent } from '../../components/section-header/section-header.component';
import { SectionItemsComponent } from '../../components/section-items/section-items.component';
import { Schemas } from '../../../api-types/storeApiTypes';

@Component({
    selector: 'app-cart-shipping',
    imports: [
        ContainerComponent,
        CurrencyPipe,
        SectionItemComponent,
        SectionHeaderComponent,
        SectionItemsComponent,
        DatePipe,
    ],
    templateUrl: './cart-shipping.component.html',
    styleUrl: './cart-shipping.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartShippingComponent {
  cartState = input.required<Schemas['Cart']>();
}
