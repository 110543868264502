import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
  PropertyGroupIds,
  PropertyValues,
  RangePropertyType,
} from '../../property.service';
import { Schemas } from '../../../api-types/storeApiTypes';

@Component({
    selector: 'app-property-range',
    imports: [NgClass],
    templateUrl: './property-range.component.html',
    styleUrl: './property-range.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyRangeComponent {
  type = input.required<RangePropertyType>();
  product = input.required<Schemas['Product']>();

  value = computed(() => {
    const groupId = PropertyGroupIds[this.type()];
    const prop = this.product().properties?.find(
      (prop) => prop?.groupId === groupId,
    );
    if (!prop || !prop.translated.name) {
      return 0;
    }
    return PropertyValues[this.type()][prop.translated.name] ?? 0;
  });

  indices = [1, 2, 3];
}
