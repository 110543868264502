import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { PropertyItemComponent } from '../../components/property-item/property-item.component';
import { PropertyRangeComponent } from '../../components/property-range/property-range.component';
import { CategoryIds } from '../../categoryIds';
import { Schemas } from '../../../api-types/storeApiTypes';

@Component({
  selector: 'app-product-facts',
  imports: [PropertyItemComponent, PropertyRangeComponent],
  templateUrl: './product-facts.component.html',
  styleUrl: './product-facts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFactsComponent {
  product = input.required<Schemas['Product']>();

  readonly showHeightPotProperties = computed(() => {
    const plant = this.product();
    return (plant.categoryIds ?? []).some(
      (c) => c === CategoryIds['Plant'] || c === CategoryIds['Pot'],
    );
  });

  readonly showPlantProperties = computed(() => {
    const plant = this.product();
    return (plant.categoryIds ?? []).some((c) => c === CategoryIds['Plant']);
  });
}
