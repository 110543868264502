import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { MediaImageComponent } from '../media-image/media-image.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { EventDirective } from '../../event.directive';
import { Schemas } from '../../../api-types/storeApiTypes';

@Component({
  selector: 'app-media-slider',
  imports: [MediaImageComponent, NgClass, EventDirective],
  templateUrl: './media-slider.component.html',
  styleUrl: './media-slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaSliderComponent {
  media = input.required<Schemas['Media'][]>();

  sortedMedia = computed(() => {
    const media = this.media();
    return media.filter((m) => m.url);
  });

  index = signal(0);

  fallbackAlt = input.required<string>();

  onScroll(evt: Event) {
    const target = evt.target as HTMLDivElement;
    const index = Math.round(target.scrollLeft / target.clientWidth);
    this.index.set(index);
  }

  goto(index: number, scrollContainer: HTMLDivElement) {
    this.index.set(index);
    this.scrollTo(index, scrollContainer);
  }

  scrollTo(index: number, scrollContainer: HTMLDivElement) {
    scrollContainer.scrollTo({
      left: this.index() * scrollContainer.clientWidth,
      behavior: window.innerWidth < window.innerHeight ? 'smooth' : 'instant',
    });
  }
}
