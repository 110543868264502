import { inject, Injectable } from '@angular/core';
import { updateCanonicalLink } from './seo';
import { Meta, Title } from '@angular/platform-browser';
import { Schemas } from '../api-types/storeApiTypes';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';

export interface MetaInfo {
  metaDescription?: string;
  metaTitle?: string;
  keywords?: string;
  description?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  readonly meta = inject(Meta);
  readonly title = inject(Title);
  readonly shopUrl = environment.storefrontUrl;
  readonly dom = inject(DOCUMENT);

  setMetaTags(meta: MetaInfo, seo: Schemas['SeoUrl'] | undefined) {
    this.meta.updateTag(
      {
        name: 'description',
        content: meta.metaDescription ?? meta.description ?? '',
      },
      'name="description"',
    );
    this.meta.updateTag(
      {
        name: 'title',
        content: meta.metaTitle ?? meta.name ?? meta.name ?? '',
      },
      'name="title"',
    );
    this.meta.updateTag(
      {
        name: 'keywords',
        content: meta.keywords ?? '',
      },
      'name="keywords"',
    );
    this.title.setTitle(meta.name + ' - Zimmerpflanzen.de');
    if (seo) {
      updateCanonicalLink(this.dom, `${this.shopUrl}/${seo.seoPathInfo}`);
    }
  }
}
