import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { SectionImageComponent } from '../section-image/section-image.component';
import { ContainerComponent } from '../../components/container/container.component';
import { MetaService } from '../../meta.service';

@Component({
    selector: 'app-about-us',
    imports: [HeaderComponent, SectionImageComponent, ContainerComponent],
    templateUrl: './about-us.component.html',
    styleUrl: './about-us.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsComponent {
  meta = inject(MetaService);

  constructor() {
    this.meta.setMetaTags(
      {
        keywords: 'zimmerpflanzen, agb',
        metaTitle: 'Über uns – Ihre Experten für Zimmerpflanzen',
        metaDescription:
          'Erfahren Sie mehr über uns und unsere Leidenschaft für Zimmerpflanzen. Mit jahrelanger Erfahrung und individueller Beratung sind wir Ihre vertrauenswürdigen Experten für gesundes, grünes Wachstum in Ihrem Zuhause. Entdecken Sie unsere Geschichte und unser Engagement für Ihre Pflanzenträume.',
      },
      undefined,
    );
  }
}
