import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ContainerComponent } from '../../components/container/container.component';
import { MetaService } from '../../meta.service';

@Component({
    selector: 'app-datenschutz',
    imports: [HeaderComponent, ContainerComponent],
    templateUrl: './datenschutz.component.html',
    styleUrl: './datenschutz.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatenschutzComponent {
  meta = inject(MetaService);

  constructor() {
    this.meta.setMetaTags(
      {
        keywords: 'zimmerpflanzen, datenschutz',
        metaTitle: 'Datenschutz',
        metaDescription:
          'Entdecken Sie unsere umfassenden Datenschutzrichtlinien für Zimmerpflanzen. Erfahren Sie, wie wir Ihre persönlichen Daten schützen und für eine sichere Nutzung unserer Dienstleistungen sorgen. Vertrauen und Transparenz stehen bei uns an erster Stelle.',
      },
      undefined,
    );
  }
}
