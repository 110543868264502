import { ChangeDetectionStrategy, Component } from '@angular/core';
import {ContainerComponent} from "../container/container.component";

@Component({
    selector: 'app-nested-section',
    imports: [
        ContainerComponent
    ],
    templateUrl: './nested-section.component.html',
    styleUrl: './nested-section.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NestedSectionComponent {

}
