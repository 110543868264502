import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  viewChild,
} from '@angular/core';
import { ContainerComponent } from '../components/container/container.component';
import { StaticAssetComponent } from '../components/static-asset/static-asset.component';

@Component({
    selector: 'app-benefit-teaser',
    imports: [ContainerComponent, StaticAssetComponent],
    templateUrl: './benefit-teaser.component.html',
    styleUrl: './benefit-teaser.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenefitTeaserComponent {
  benefits = [
    {
      text: 'Lieferung deutschlandweit',
      image: 'assets/static/Schnelle_Sichere_Lieferung_180',
    },
    {
      text: 'Kostenloser Versand ab 60€',
      image: 'assets/static/Vertrauensvoll_180',
    },
    {
      text: 'in 2-4 Werktagen geliefert',
      image: 'assets/static/Jede_Woche_Neue_Angebote',
    },
  ];

  element = viewChild<ElementRef<HTMLElement>>('container');
}
