@if (collapsable()) {
  <button
    class="py-3 bg-white md:bg-transparent border-b border-b-gray-300 md:border-0 w-full"
    (click)="toggle()"
  >
    <div class="flex px-6 md:px-0 w-full items-center">
      <div
        class="flex-grow min-w-0 md:border-b border-gray-200 flex justify-between"
      >
        <span>{{ label() }}</span>
        <span class="md:hidden" [ngClass]="{ hidden: open() }">
          <ng-content select="app-collapsable-option-header"></ng-content>
        </span>
      </div>
      <div class="px-2 md:hidden">
        <div [ngClass]="{ 'rotate-90': open() }" class="transition-all">
          <fa-icon [icon]="faCaretRight"></fa-icon>
        </div>
      </div>
    </div>
  </button>
} @else if (showLabel()) {
  <div class="py-3">
    <span class="md:underline decoration-primary">{{ label() }}</span>
  </div>
}

<div
  [ngClass]="{ 'max-h-infiniti': open(), 'max-h-0': !open() }"
  class="overflow-hidden bg-gray-100 md:bg-transparent px-3 md:px-0 md:max-h-infiniti"
>
  <div class="py-3 md:py-0">
    <ng-content></ng-content>
  </div>
</div>
