import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  output,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ProductListService } from '../../product-list.service';
import { EventDirective } from '../../event.directive';
import { CollapsableOptionComponent } from '../../components/collapsable-option/collapsable-option.component';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AvailableFilter,
  AvailableFilterOption,
  FilterQueryValue,
  serializeFilterQueryValue,
} from '../../filteredListStore';
import { CollapsableOptionGroupComponent } from '../../components/collapsable-option-group/collapsable-option-group.component';
import { CollapsableOptionHeaderComponent } from '../../components/collapsable-option-header/collapsable-option-header.component';

@Component({
  selector: 'app-product-filter',
  imports: [
    FaIconComponent,
    EventDirective,
    CollapsableOptionComponent,
    CollapsableOptionGroupComponent,
    CollapsableOptionHeaderComponent,
  ],
  templateUrl: './product-filter.component.html',
  styleUrl: './product-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFilterComponent {
  readonly products = inject(ProductListService);
  readonly router = inject(Router);
  readonly route = inject(ActivatedRoute);

  readonly faTimes = faTimes;

  open = model<boolean>(false);
  collapsable = input<boolean>(false);
  selected = output<AvailableFilterOption | null>();
  showLabel = input<boolean>(false);

  async select(filter: AvailableFilter, option: AvailableFilterOption) {
    const params = { ...this.route.snapshot.queryParams };
    if (params[filter.type]) {
      if (params[filter.type] instanceof Array) {
        params[filter.type] = [
          ...params[filter.type],
          serializeFilterQueryValue(option),
        ];
      } else {
        params[filter.type] = [
          params[filter.type],
          serializeFilterQueryValue(option),
        ];
      }
    } else {
      params[filter.type] = serializeFilterQueryValue(option);
    }

    await this.router.navigate(['.'], {
      queryParams: params,
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
    this.selected.emit(option);
  }

  async reset(type: string, value: FilterQueryValue, evt: Event) {
    evt.preventDefault();
    const params = { ...this.route.snapshot.queryParams };
    if (params[type]) {
      if (params[type] instanceof Array) {
        params[type] = params[type].filter(
          (v: string) => v !== serializeFilterQueryValue(value),
        );
      } else {
        delete params[type];
      }
    }

    await this.router.navigate(['.'], {
      queryParams: params,
      queryParamsHandling: '',
      relativeTo: this.route,
    });
    this.selected.emit(null);
  }

  appliedFilters = computed(() => {
    const queryFilter = this.products.productStore();

    return queryFilter.appliedFilters;
  });

  filterDescription = computed(() => {
    const appliedFilters = this.appliedFilters();
    const texts: string[] = [];
    for (const filter of appliedFilters) {
      texts.push(
        `${filter.type}: ${filter.values.map((v) => v.label).join(' oder ')}`,
      );
    }

    return texts.join(', ');
  });
}
