<app-header [header]="'Bestellung erfolgreich'"></app-header>
<app-container>
  <div class="my-8" *ngIf="order() as state">
    @if (state.loading) {
      <app-spinner></app-spinner>
    } @else {
      <div class="prose">
        <h2>Vielen Dank für Ihre Bestellung</h2>
        <p>
          Die Zahlung wurde erfolgreich abgeschlossen.
          Ihre Bestellung wurde erfolgreich entgegengenommen und wird in Kürze bearbeitet.
          Sie erhalten in Kürze eine Bestätigung per E-Mail.
        </p>
      </div>

      <app-button-primary class="mt-8" [action]="goToTheShop">Zurück zum Shop</app-button-primary>
    }
  </div>
</app-container>
