import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { StaticAssetComponent } from '../static-asset/static-asset.component';
import { ButtonPrimaryComponent } from '../button-primary/button-primary.component';

@Component({
    selector: 'app-not-found',
    imports: [ButtonPrimaryComponent, StaticAssetComponent],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent {
  router = inject(Router);

  goToShop = () => {
    return this.router.navigate(['/Zimmerpflanzen']);
  };
}
