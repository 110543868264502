import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContainerComponent } from '../../components/container/container.component';
import { HeaderComponent } from '../../components/header/header.component';

@Component({
    selector: 'app-wiederrufsrecht',
    imports: [ContainerComponent, HeaderComponent],
    templateUrl: './wiederrufsrecht.component.html',
    styleUrl: './wiederrufsrecht.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WiederrufsrechtComponent {}
