import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-collapsable',
    imports: [
        FaIconComponent,
        NgClass,
    ],
    templateUrl: './collapsable.component.html',
    styleUrl: './collapsable.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsableComponent {

  open = signal(false)

  toggle() {
    this.open.update(s => !s);
  }

  protected readonly faCaretRight = faCaretRight;
}
