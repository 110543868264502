<app-section-items>
  <app-section-header>Promotionscode</app-section-header>
  <app-section-item>
    <div class="flex w-full gap-x-3 pb-2 items-end">
      <app-input
        [label]="'Code'"
        class="flex-grow min-w-0"
        [control]="cartForm.controls.promotionCode"
      ></app-input>
      <app-button-primary
        [action]="applyCoupon"
        [color]="'green'"
        [size]="'small'"
        class="flex-shrink-0"
        >Einlösen</app-button-primary
      >
    </div>
  </app-section-item>
</app-section-items>
