import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { NgClass } from '@angular/common';

export interface FaqItem {
  id: string;
  question: string;
  answer: string;
}

@Component({
    selector: 'app-faq-list',
    imports: [NgClass],
    templateUrl: './faq-list.component.html',
    styleUrl: './faq-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqListComponent {
  items = input.required<FaqItem[]>();

  collapsedItem = signal<FaqItem | null>(null);

  toggle(item: FaqItem) {
    this.collapsedItem.update((state) => {
      if (state === item) {
        return null;
      } else {
        return item;
      }
    });
  }
}
