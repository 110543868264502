<video
  [appEvent]="'play-video'"
  #player
  playsinline
  controls
  style="height: 100%; width: 100%"
  class="overflow-hidden"
  [attr.data-poster]="poster()"
>
  <!--  data-poster="{content.poster}"-->
  @for (source of sources(); track source.src) {
    <source [src]="source.src" [type]="source.type" />
  }
</video>
