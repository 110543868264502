<app-collapsable-option-group
  [label]="'Kategorie'"
  [(open)]="open"
  [showLabel]="showLabel()"
  [collapsable]="collapsable()"
  itemscope
  itemtype="https://schema.org/BreadcrumbList"
  [selected]="selectedCategories()"
>
  <app-collapsable-option-header>
    {{ selectedCategories() }}
  </app-collapsable-option-header>

  @for (categoryOption of category.mainCategories(); track categoryOption.id) {
    <app-collapsable-option
      (click)="selected.emit(categoryOption)"
      itemprop="itemListElement"
      itemscope
      itemtype="https://schema.org/ListItem"
      [link]="categoryOption.url"
      [label]="categoryOption.name"
      [id]="categoryOption.name"
    >
      <meta itemprop="position" [content]="$index" />
    </app-collapsable-option>
  }
</app-collapsable-option-group>
