import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { Schemas } from '../../../api-types/storeApiTypes';
import { getPropertyValue } from '../property/getPropertyValue';
import { CategoryIds } from '../../categoryIds';
import { PropertyGroupIds } from '../../property.service';

@Component({
  selector: 'app-product-properties',
  templateUrl: './product-properties.component.html',
  styleUrl: './product-properties.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPropertiesComponent {
  product = input.required<Schemas['Product']>();

  groupedProperties = computed(() => {
    const product = this.product();
    const props = product.properties ?? [];
    const grouped: { id: string; name: string; values: string[] }[] = [];

    const isAccessory = (product.categoryIds ?? []).some(
      (c) => c === CategoryIds['Accessory'],
    );
    const propsToRemove = isAccessory
      ? [PropertyGroupIds['height'], PropertyGroupIds['potsize']]
      : [];

    props.forEach((prop) => {
      if (propsToRemove.indexOf(prop.group.id) >= 0) {
        return;
      }

      const value = getPropertyValue(prop.group.id, prop.name);
      if (!value) {
        return;
      }

      const existing = grouped.find((g) => g.id === prop.group.id);
      if (!existing) {
        grouped.push({
          id: prop.group.id,
          name: prop.group.name,
          values: [value],
        });
      } else if (existing.values.indexOf(value) === -1) {
        existing.values.push(value);
      }
    });

    return grouped
      .map((group) => ({
        id: group.id,
        name: group.name,
        value: group.values.join(', '),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  });
}
