<a
  [routerLink]="link()"
  [ngClass]="{ active: active() }"
  [attr.aria-label]="text()"
  [title]="text()"
  class="item relative"
>
  <fa-icon [icon]="icon()"></fa-icon>
  <span class="hidden md:inline">{{ text() }}</span>

  @if (badge(); as badge) {
    <span
      class="bg-primary-orange h-6 w-6 flex items-center justify-center text-white px-1 py-1 absolute -top-2 md:-right-8 md:top-0 -right-2 text-xs rounded-full"
      >{{ badge }}
      <span
        class="border-2 rounded-full absolute h-6 w-6 border-primary-orange badge"
      >
      </span>
    </span>
  }
</a>
