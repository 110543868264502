import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ContainerComponent } from '../components/container/container.component';

@Component({
  selector: 'app-teaser-section',
  imports: [ContainerComponent],
  templateUrl: './teaser-section.component.html',
  styleUrl: './teaser-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeaserSectionComponent {
  reversed = input<boolean>(false);
}
