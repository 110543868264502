@for (point of points; track $index) {
  <svg
    (click)="pointSelected.emit(point)"
    class="{{ size() }} flex-shrink-0"
    [ngClass]="{
      'text-yellow-400': (selectedPoint() ?? 0) >= point,
      'text-gray-300': (selectedPoint() ?? 0) < point,
    }"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
    data-slot="icon"
  >
    <path
      fill-rule="evenodd"
      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z"
      clip-rule="evenodd"
    />
  </svg>
}
