import { Schemas } from '../api-types/storeApiTypes';

export function propertyFilter(
  groupName: string,
  values: string[],
): Schemas['MultiNotFilter'] {
  return {
    type: 'multi',
    operator: 'and',
    queries: [
      {
        type: 'equals',
        field: 'properties.groupId',
        value: groupName,
      },
      values.length === 1
        ? {
            type: 'equals',
            field: 'properties.name',
            value: values[0],
          }
        : {
            type: 'multi',
            operator: 'or',
            queries: values.map((value) => ({
              type: 'equals',
              field: 'properties.name',
              value: value,
            })),
          },
    ],
  };
}
