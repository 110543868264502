import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
} from '@angular/core';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { ProductListItemComponent } from '../../components/product-list-item/product-list-item.component';
import { HeaderComponent } from '../../components/header/header.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MediaImageComponent } from '../../components/media-image/media-image.component';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';
import { Router } from '@angular/router';
import { CartService } from '../../cart.service';
import { ContainerComponent } from '../../components/container/container.component';
import { AnalyticsService } from '../../analytics.service';
import { FormBuilder } from '@angular/forms';
import { CartItemsComponent } from '../cart-items/cart-items.component';
import { CartShippingComponent } from '../cart-shipping/cart-shipping.component';
import { CartPromotionCodeComponent } from '../cart-promotion-code/cart-promotion-code.component';
import { CartTotalComponent } from '../cart-total/cart-total.component';
import { CartEmptyComponent } from '../cart-empty/cart-empty.component';

@Component({
  selector: 'app-cart',
  imports: [
    HeaderComponent,
    ButtonPrimaryComponent,
    ContainerComponent,
    CartItemsComponent,
    CartShippingComponent,
    CartPromotionCodeComponent,
    CartTotalComponent,
    CartEmptyComponent,
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartComponent {
  readonly cart = inject(CartService);
  readonly router = inject(Router);
  readonly analytics = inject(AnalyticsService);
  readonly fb = inject(FormBuilder);

  store = this.cart.store;

  constructor() {
    effect(() => {
      const cart = this.store();
      if (cart.loading) {
        return;
      }

      this.analytics.event('view_cart', {
        currency: 'EUR',
        value: cart.price.totalPrice,
        items: (cart.lineItems ?? []).map((item, i) => ({
          item_id: item.referencedId,
          item_name: item.label,
          index: i,
          price: item.price?.unitPrice ?? 0,
          quantity: item.quantity,
        })),
      });
    });
  }

  checkout = () => {
    return this.router.navigate(['/checkout/finalize']);
  };
}
