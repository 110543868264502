<app-container>
  <footer class="grid grid-cols-4 my-16">
    <div class="flex flex-col">
      <!--      <div>Informationen</div>-->
      <div class="text-sm font-semibold leading-6 uppercase text-gray-700">
        Informationen
      </div>
      <div class="mt-6 space-y-4 flex flex-col">
        <a [routerLink]="['/Informationen/Ueber-uns']" title="Über uns"
          >Über uns</a
        >
        <a
          [routerLink]="['/Informationen/Versand']"
          title="Versandinformationen"
          >Versandinformationen</a
        >
        <a
          [routerLink]="['/Informationen/Zahlungsmethoden']"
          title="Zahlungsmethoden"
          >Zahlungsmethoden</a
        >
        <a
          [routerLink]="['/Informationen/Schadensmeldung']"
          title="Schadensmeldung"
          >Schadensmeldung</a
        >
      </div>
    </div>
    <div class="flex flex-col">
      <!--      <div>Legal</div>-->
      <div class="text-sm font-semibold leading-6 uppercase text-gray-700">
        Legal
      </div>
      <div class="mt-6 space-y-4 flex flex-col">
        <a [routerLink]="['/Informationen/AGB']" title="AGB">AGB</a>
        <a [routerLink]="['/Informationen/Datenschutz']" title="Datenschutz"
          >Datenschutz</a
        >
        <a
          [routerLink]="['/Informationen/Wiederrufsrecht']"
          title="Wiederrufsrecht"
          >Wiederrufsrecht</a
        >
        <a [routerLink]="['/Informationen/Impressum']" title="Impressum"
          >Impressum</a
        >
      </div>
    </div>
    <div class="flex flex-col">
      <div class="text-sm font-semibold leading-6 uppercase text-gray-700">
        Social Media
      </div>
      <div class="mt-6 space-y-4 flex flex-col">
        <a
          target="_blank"
          href="https://www.instagram.com/zimmerpflanzen.de/"
          title="Instagram - Zimmerpflanzen.de"
          class="flex hover:text-[#dd2a7b]"
        >
          <div
            class="bg-[#dd2a7b] w-6 h-6 mr-2 flex items-center justify-center rounded-md"
          >
            <fa-icon [icon]="instagramIcon" class="text-white"></fa-icon>
          </div>
          Instagram
        </a>
        <a
          target="_blank"
          href="https://zimmerpflanzen.de/blog"
          title="Blog - Zimmerpflanzen.de"
          class="flex hover:text-[#dd2a7b]"
        >
          <div
            class="bg-primary w-6 h-6 mr-2 flex items-center justify-center rounded-md"
          >
            <fa-icon [icon]="blogIcon" class="text-white"></fa-icon>
          </div>
          Blog
        </a>
      </div>
    </div>
    <div class="flex items-end flex-col">
      <app-static-asset
        [alt]="'zimmerpflanzen.de'"
        [extension]="'png'"
        [loading]="'eager'"
        [source]="'assets/static/logo_combined_vertical_h120'"
        class="h-32 mt-6"
      ></app-static-asset>
    </div>
    <div class="text-gray-600 mt-2 text-right col-span-4">
      Uns gibt es auch in der Schweiz unter
      <a href="https://zimmerpflanzen.ch" class="underline decoration-primary"
        >zimmerpflanzen.ch</a
      >
    </div>
  </footer>
</app-container>
