import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { CategoryService } from './category.service';
import { environment } from '../environments/environment';

export const setCategoryFilterGuard: CanActivateFn = async (route, state) => {
  const category = inject(CategoryService);
  const categories = await category.store().promise;
  const matchingCategory = categories.elements.find(
    (e) => e.url === new URL(environment.baseUrl + state.url).pathname + '/',
  );

  category.current.set(matchingCategory ?? null);

  return true;
};
