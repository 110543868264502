<app-benefit-teaser></app-benefit-teaser>

@if (plant.state(); as plantState) {
  <div class="flex flex-col" itemscope itemtype="https://schema.org/Product">
    @if (plantState.loading) {
      <app-spinner></app-spinner>
    } @else if (plantState.element) {
      <app-product-breadcrumb
        [product]="plantState.element"
      ></app-product-breadcrumb>

      <app-product-meta [product]="plantState.element"></app-product-meta>

      <app-container [padding]="'md:px-6'">
        <div class="contents md:flex md:flex-row">
          <app-media-slider
            class="md:flex-grow md:min-w-0"
            [media]="media()"
            [fallbackAlt]="plantState.element.name"
          ></app-media-slider>

          <div class="contents md:flex md:flex-col md:w-96">
            <div
              class="px-6 py-6 md:pt-12 text-center flex md:text-left md:px-0"
            >
              <div class="flex-grow min-w-0">
                <h1
                  class="text-2xl underline decoration-primary"
                  itemprop="name"
                >
                  {{ plantState.element.name }}
                </h1>
                <div class="flex justify-center md:justify-start items-center">
                  <div
                    [ngClass]="{
                      'text-red-700':
                        plantState.element.calculatedPrice.listPrice &&
                        plantState.element.markAsTopseller,
                    }"
                  >
                    {{
                      plantState.element.calculatedPrice.totalPrice | currency
                    }}
                  </div>
                  @if (
                    plantState.element.calculatedPrice.listPrice &&
                    plantState.element.markAsTopseller
                  ) {
                    <div class="line-through text-sm ml-2 font-light">
                      {{
                        plantState.element.calculatedPrice.listPrice.price
                          | currency
                      }}
                    </div>
                  }
                </div>
              </div>
              <div class="flex-shrink-0">
                @if (isOnWishlist(); as wishlistState) {
                  <button
                    (click)="
                      toggleFavorite(plantState.element, wishlistState.state)
                    "
                    [ngClass]="{
                      'bg-primary text-white': wishlistState.state,
                      'text-primary': !wishlistState.state,
                    }"
                    class="rounded-full border border-primary h-10 w-10 flex items-center justify-center"
                  >
                    <fa-icon [icon]="favoriteIcon"></fa-icon>
                  </button>
                }
              </div>
            </div>

            <app-product-facts
              [product]="plantState.element"
            ></app-product-facts>

            <div
              class="sticky md:relative md:top-auto top-16 w-full px-6 z-20 bg-gray-100 border-y py-2 md:py-0 border-gray-300 md:border-y-0 md:bg-white md:px-0"
            >
              <app-product-cart-section
                [product]="plantState.element"
              ></app-product-cart-section>
            </div>

            <app-product-benefits
              class="md:mt-12"
              [product]="plantState.element"
            ></app-product-benefits>
          </div>
        </div>

        <div class="px-6 md:px-0 py-8">
          <h2 class="text-2xl mb-8 underline decoration-primary">
            Beschreibung
          </h2>
          <div
            class="prose !max-w-none"
            itemprop="description"
            [innerHTML]="productDescription()"
          ></div>

          <app-product-tips [product]="plantState.element"></app-product-tips>

          <app-product-properties
            class="md:my-16"
            [product]="plantState.element"
          ></app-product-properties>

          <app-product-review
            [product]="plantState.element"
          ></app-product-review>
        </div>
      </app-container>

      @if (familyPlants().elements.length !== 0) {
        <div class="bg-primary-light/20 py-16">
          @defer (on viewport) {
            <app-container>
              <h2 class="mb-6 decoration-primary underline text-2xl">
                Ähnliche Pflanzen aus der Familie
                {{ familyPlants().filter?.family }}
              </h2>
              <app-product-list
                (selected)="similarSelected()"
                [canReset]="false"
                [store]="familyPlants()"
              ></app-product-list>
            </app-container>
          } @placeholder {
            <div [style.height.px]="2000"></div>
          }
        </div>
      }
    } @else {
      <app-not-found></app-not-found>
    }
  </div>
}
