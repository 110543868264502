import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { NgOptimizedImage } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentMethodService } from '../../payment-method.service';
import { ContainerComponent } from '../../components/container/container.component';
import { MetaService } from '../../meta.service';

@Component({
    selector: 'app-payment',
    imports: [
        HeaderComponent,
        ContainerComponent,
        NgOptimizedImage,
        ReactiveFormsModule,
    ],
    templateUrl: './payment.component.html',
    styleUrl: './payment.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent {
  paymentMethod = inject(PaymentMethodService);
  paymentMethods = this.paymentMethod.paymentMethods;

  meta = inject(MetaService);

  constructor() {
    this.meta.setMetaTags(
      {
        keywords: 'zimmerpflanzen, zahlungsmittel',
        metaTitle:
          'Übersicht der Zahlungsmöglichkeiten für Zimmerpflanzen - Einfach und Bequem Bezahlen',
        metaDescription:
          'Entdecken Sie alle verfügbaren Zahlungsmethoden für den Kauf von Zimmerpflanzen. Informieren Sie sich über sichere und bequeme Zahlungsoptionen, um Ihre grünen Freunde mühelos nach Hause zu holen.',
      },
      undefined,
    );
  }
}
