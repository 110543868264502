import { Routes } from '@angular/router';
import { setCategoryFilterGuard } from './set-category-filter.guard';
import { ProductDetailPageComponent } from './products/product-detail-page/product-detail-page.component';
import { detailStateGuard } from './products/detail-state.guard';
import { CartComponent } from './checkout/cart/cart.component.js';
import { OrderSuccessComponent } from './checkout/order-success/order-success.component.js';
import { orderGuard } from './checkout/order.guard.js';
import { OrderErrorComponent } from './checkout/order-error/order-error.component.js';
import { ProductCategoryPageComponent } from './products/product-category-page/product-category-page.component';
import { HomeComponent } from './home/home.component.js';
import { InfoComponent } from './info/info.component.js';
import { AboutUsComponent } from './info/about-us/about-us.component.js';
import { ShippingComponent } from './info/shipping/shipping.component.js';
import { PaymentComponent } from './info/payment/payment.component.js';
import { RefundComponent } from './info/refund/refund.component.js';
import { AgbComponent } from './info/agb/agb.component.js';
import { DatenschutzComponent } from './info/datenschutz/datenschutz.component.js';
import { ImpressumComponent } from './info/impressum/impressum.component.js';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CartAndCheckoutComponent } from './checkout/cart-and-checkout/cart-and-checkout.component';
import { WiederrufsrechtComponent } from './info/wiederrufsrecht/wiederrufsrecht.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    title: 'Zimmerpflanzen und Blumentöpfe online kaufen',
    //data: { revalidate: 60 * 60 },
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'Informationen',
    //data: { revalidate: 60 * 60 },
    children: [
      {
        path: '',
        component: InfoComponent,
      },
      {
        path: 'Ueber-uns',
        component: AboutUsComponent,
        title: 'Über uns - Zimmerpflanzen.de',
      },
      {
        path: 'Versand',
        component: ShippingComponent,
        title: 'Versand - Zimmerpflanzen.de',
      },
      {
        path: 'Zahlungsmethoden',
        component: PaymentComponent,
        title: 'Zahlungsmethoden - Zimmerpflanzen.de',
      },
      {
        path: 'Schadensmeldung',
        component: RefundComponent,
        title: 'Schadensmeldung - Zimmerpflanzen.de',
      },
      {
        path: 'Wiederrufsrecht',
        component: WiederrufsrechtComponent,
        title: 'Wiederrufsrecht - Zimmerpflanzen.de',
      },
      {
        path: 'AGB',
        component: AgbComponent,
        title: 'AGB - Zimmerpflanzen.de',
      },
      {
        path: 'Datenschutz',
        component: DatenschutzComponent,
        title: 'Datenschutz - Zimmerpflanzen.de',
      },
      {
        path: 'Impressum',
        component: ImpressumComponent,
        title: 'Impressum - Zimmerpflanzen.de',
      },
    ],
  },
  {
    path: 'checkout',
    children: [
      {
        path: 'cart',
        component: CartComponent,
        title: 'Warenkorb - Zimmerpflanzen.de',
      },
      {
        path: 'cart_checkout',
        component: CartAndCheckoutComponent,
        title: 'Warenkorb / Kasse - Zimmerpflanzen.de',
      },
      {
        loadChildren: () =>
          import('./checkout/checkout.module').then((m) => m.CheckoutModule),
        path: 'finalize',
      },
      {
        path: ':orderId/success',
        component: OrderSuccessComponent,
        canActivate: [orderGuard],
        title: 'Bestellung erfolgreich - Zimmerpflanzen.de',
      },
      {
        path: ':orderId/error',
        component: OrderErrorComponent,
        canActivate: [orderGuard],
        title: 'Bestellung fehlgeschlagen - Zimmerpflanzen.de',
      },
      {
        path: '',
        redirectTo: 'cart',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'Service/Versand',
    redirectTo: 'Informationen/Versand',
  },
  {
    path: 'administration',
    redirectTo: 'Informationen/Schadensmeldung',
  },
  {
    path: 'administration',
    redirectTo: 'Informationen/Schadensmeldung',
  },
  {
    path: 'UEber-uns',
    redirectTo: 'Informationen/Ueber-uns',
  },

  {
    path: 'product/:number',
    component: ProductDetailPageComponent,
    canActivate: [detailStateGuard],
  },
  {
    path: ':name/:number',
    component: ProductDetailPageComponent,
    canActivate: [detailStateGuard],
  },
  {
    path: ':name',
    canActivate: [setCategoryFilterGuard],
    component: ProductCategoryPageComponent,
  },

  {
    path: '**',
    component: NotFoundComponent,
  },
];
