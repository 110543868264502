<div class="bg-primary-light text-sm text-primary">
  <app-container>
    <div
      class="py-1 flex relative md:items-center md:justify-between overflow-hidden"
    >
      @for (benefit of benefits; track benefit) {
        <div
          class="flex-shrink-0 md:w-auto flex items-center w-full animation-{{
            $index + 1
          }}"
        >
          <app-static-asset
            [source]="benefit.image"
            [alt]="benefit.text"
            [extensions]="['png']"
            [extension]="'png'"
            class="h-12 md:h-8 w-20 md:w-auto mr-3"
          ></app-static-asset>
          <div class="text-lg md:text-base">{{ benefit.text }}</div>
        </div>
      }
    </div>
  </app-container>
</div>
