<app-dialog
  #dialog
  [autoShow]="!cookieState.cookieConsent"
  [preventClose]="true"
>
  <div class="border-b border-gray-300 py-3">
    <app-static-asset
      [alt]="'zimmerpflanzen.de'"
      [extension]="'png'"
      [loading]="'eager'"
      [source]="'assets/static/logo_combined_horizontal_h60'"
      class="h-6"
    ></app-static-asset>
  </div>

  <app-container>
    <div class="flex flex-col items-center gap-y-6 py-6">
      <app-static-asset
        [alt]="'Cookie Plant'"
        [extension]="'png'"
        [source]="'assets/static/cookie_plant-transparent'"
        class="w-24 object-contain"
      ></app-static-asset>

      <p>
        Diese Website verwendet Cookies, um eine bestmögliche Erfahrung bieten
        zu können.
      </p>

      <div class="flex-grow min-w-0 w-full">
        <div
          [ngClass]="{ 'max-h-infiniti': more(), 'max-h-0': !more() }"
          class="overflow-hidden md:max-h-infiniti"
        >
          <div
            class="flex flex-col py-3 gap-y-1 divide-y-gray-200 divide-y pl-1 marker:text-primary"
          >
            <app-collapsable>
              <app-collapsable-header>
                <label class="space-x-2">
                  <input
                    type="checkbox"
                    class="h-4 w-4 border-gray-300 text-primary focus:ring-primary disabled:bg-gray-800"
                    [formControl]="form.controls.functional"
                  />
                  <span>Technisch erforderliche</span>
                </label>
              </app-collapsable-header>
              <app-collapsable-body>
                <ul class="text-gray-500 list-disc list-inside px-1.5">
                  <li>Sitzung</li>
                  <li>Zeitzone</li>
                  <li>CAPTCHA-Integration</li>
                  <li>Zahlungsdienstleister (Stripe)</li>
                </ul>
              </app-collapsable-body>
            </app-collapsable>

            <app-collapsable>
              <app-collapsable-header>
                <label class="space-x-2">
                  <input
                    type="checkbox"
                    class="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                    [formControl]="form.controls.analytics"
                  />
                  <span>Statistik</span>
                </label>
              </app-collapsable-header>
              <app-collapsable-body>
                <ul class="text-gray-500 list-disc list-inside px-1.5">
                  <li>Google Analytics</li>
                  <li>Kauf- und Surfverhalten mit Google Tag Manager</li>
                </ul>
              </app-collapsable-body>
            </app-collapsable>

            <app-collapsable>
              <app-collapsable-header>
                <label class="space-x-2">
                  <input
                    type="checkbox"
                    class="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                    [formControl]="form.controls.marketing"
                  />
                  <span>Marketing</span>
                </label>
              </app-collapsable-header>
              <app-collapsable-body>
                <ul class="text-gray-500 list-disc list-inside px-1.5">
                  <li>Google Werbung und Marketing</li>
                  <li>Youtube / Vimeo</li>
                </ul>
              </app-collapsable-body>
            </app-collapsable>
          </div>
        </div>
        <div class="gap-y-2 flex flex-col md:flex-row md:gap-x-4">
          <button
            class="md:flex w-full justify-center items-center space-x-3 rounded-md text-sm px-8 py-3 leading-6 font-semibold shadow-sm bg-black/70 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            [ngClass]="{ flex: more(), hidden: !more() }"
            (click)="accept(dialog)"
            [appEvent]="'accept-selection-cookie'"
          >
            Auswahl akzeptieren
          </button>
          <button
            class="md:hidden w-full justify-center items-center space-x-3 rounded-md text-sm px-8 py-3 leading-6 font-semibold shadow-sm bg-black/20 text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            (click)="toggleInfo()"
            [ngClass]="{ flex: !more(), hidden: more() }"
            [appEvent]="'more-cookie-info'"
          >
            Mehr Informationen
          </button>
          <button
            class="flex w-full justify-center items-center space-x-3 rounded-md text-sm px-8 py-3 leading-6 font-semibold shadow-sm bg-black/70 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            (click)="acceptAll(dialog)"
            [appEvent]="'accept-all-cookie'"
          >
            Alle akzeptieren
          </button>
        </div>
      </div>
    </div>
  </app-container>
</app-dialog>
