import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HeaderComponent } from '../components/header/header.component';
import { SectionHeaderComponent } from '../components/section-header/section-header.component';
import { SectionItemsComponent } from '../components/section-items/section-items.component';
import { SectionItemComponent } from '../components/section-item/section-item.component';
import { StaticAssetComponent } from '../components/static-asset/static-asset.component';
import { ContainerComponent } from '../components/container/container.component';

@Component({
    selector: 'app-info',
    imports: [
        RouterLink,
        HeaderComponent,
        SectionHeaderComponent,
        SectionItemsComponent,
        SectionItemComponent,
        StaticAssetComponent,
        ContainerComponent,
    ],
    templateUrl: './info.component.html',
    styleUrl: './info.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent {}
