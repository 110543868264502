import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { Schemas } from '../../../api-types/storeApiTypes';
import { StaticAssetComponent } from '../../components/static-asset/static-asset.component';

const plantTips: Record<
  string,
  { title: string; icon: string; order: number }
> = {
  location: {
    title: 'Standort',
    icon: 'assets/static/icons/Icon_Standort_01',
    order: 1,
  },
  temperature: {
    title: 'Temperatur',
    icon: 'assets/static/icons/Icon_Temperatur',
    order: 2,
  },
  water: {
    title: 'Bewässerung',
    icon: 'assets/static/icons/Icon_Bewässerung_01',
    order: 3,
  },
  humidity: {
    title: 'Luftfeuchtigkeit',
    icon: 'assets/static/icons/Icon_Luftfeuchtigkeit',
    order: 4,
  },
  ground: {
    title: 'Boden',
    icon: 'assets/static/icons/Icon_Boden',
    order: 5,
  },
  fertilize: {
    title: 'Düngen',
    icon: 'assets/static/icons/Icon_Duenger_',
    order: 6,
  },
  repot: {
    title: 'Umtopfen',
    icon: 'assets/static/icons/Icon_Umtopfen',
    order: 7,
  },
  cutting: {
    title: 'Beschneidung',
    icon: 'assets/static/icons/Icon_Beschneidung',
    order: 8,
  },
  disease: {
    title: 'Krankheiten und Schädlinge',
    icon: 'assets/static/icons/Icon_Schaedlinge',
    order: 9,
  },
};

export interface PlantTip {
  title: string;
  description: string;
  icon: string;
  order: number;
}

const isPlantTip = (obj: PlantTip | undefined): obj is PlantTip => !!obj;

@Component({
  selector: 'app-product-tips',
  imports: [StaticAssetComponent],
  templateUrl: './product-tips.component.html',
  styleUrl: './product-tips.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTipsComponent {
  product = input.required<Schemas['Product']>();

  readonly plantTips = computed(() => {
    const plant = this.product();
    const translated = plant.translated as any;
    if (!translated.customFields) {
      return [];
    }

    return Object.entries(translated.customFields)
      .filter(([key, value]) => key.startsWith('custom_descriptions_') && value)
      .map<PlantTip | undefined>(([key, value]) => {
        const tip = plantTips[key.substring('custom_descriptions_'.length)];
        if (!tip) {
          return undefined;
        }
        return {
          title: tip.title,
          description: value as string,
          icon: tip.icon,
          order: tip.order,
        };
      })
      .filter(isPlantTip)
      .sort((f, s) => f.order - s.order);
  });
}
