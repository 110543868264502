import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { SectionItemComponent } from '../../components/section-item/section-item.component';
import { RouterLink } from '@angular/router';
import { MediaImageComponent } from '../../components/media-image/media-image.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { StepperComponent } from '../../components/stepper/stepper.component';
import { PriceBadgeComponent } from '../../components/price-badge/price-badge.component';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';
import { CartService } from '../../cart.service';
import { Schemas } from '../../../api-types/storeApiTypes';
import { CurrencyPipe } from '@angular/common';
import { faBadgePercent } from '@fortawesome/pro-duotone-svg-icons';
import { SectionHeaderComponent } from '../../components/section-header/section-header.component';
import { SectionItemsComponent } from '../../components/section-items/section-items.component';

@Component({
    selector: 'app-cart-items',
    imports: [
        SectionItemComponent,
        RouterLink,
        MediaImageComponent,
        FaIconComponent,
        StepperComponent,
        PriceBadgeComponent,
        ButtonPrimaryComponent,
        CurrencyPipe,
        SectionHeaderComponent,
        SectionItemsComponent,
    ],
    templateUrl: './cart-items.component.html',
    styleUrl: './cart-items.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartItemsComponent {
  promotionIcon = faBadgePercent;

  cart = inject(CartService);
  cartState = input.required<Schemas['Cart']>();

  removeCoupon(item: Schemas['LineItem']) {
    return async () => {
      await this.cart.updateQuantity(item, 0);
    };
  }

  async updateQuantity(item: Schemas['LineItem'], quantity: number) {
    await this.cart.updateQuantity(item, quantity);
  }
}
