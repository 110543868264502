<div class="bg-white flex flex-col ml-4 md:max-w-md w-full flex-grow min-h-0">
  <div
    class="px-6 py-3 flex items-center w-full border-b border-gray-100 mr-safe"
  >
    <button
      aria-label="Menu schliessen"
      class="text-4xl text-gray-600"
      (click)="close()"
    >
      <fa-icon [icon]="closeIcon"></fa-icon>
    </button>
    <div class="px-6">
      <app-static-asset
        [alt]="'zimmerpflanzen.de'"
        [extension]="'png'"
        [source]="'assets/static/logo_combined_horizontal_h60'"
        class="h-6"
      ></app-static-asset>
    </div>
  </div>

  <div class="flex-grow px-6 py-6 flex-col min-h-0 overflow-y-auto">
    <div
      class="text-primary-orange border border-primary-orange bg-primary-orange/10 px-4 py-2 rounded"
    >
      Diese Funktion erfordert ein Kundenkonto.
    </div>

    <app-registration-form (registered)="close()"></app-registration-form>
  </div>
</div>
