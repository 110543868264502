import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { PlantFilter } from '../../product-list.service';
import { CollapsableOptionGroupComponent } from '../collapsable-option-group/collapsable-option-group.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-filter-options',
  imports: [CollapsableOptionGroupComponent],
  templateUrl: './filter-options.component.html',
  styleUrl: './filter-options.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterOptionsComponent {
  label = input.required<string>();

  filter = input.required<PlantFilter>();
  property = input.required<string>();

  mode = input.required<'single' | 'many'>();
  router = inject(Router);
  route = inject(ActivatedRoute);

  selected = computed(() => {
    const filters = this.filter().appliedQuery ?? [];
    const type = this.label();
    const filter = filters.find((f) => f.type === type);
    if (!filter || filter.values.length === 0) {
      return ['Alle'];
    } else {
      return filter.values.map((v) => v.id);
    }
  });
}
