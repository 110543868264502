import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Schemas } from '../../../api-types/storeApiTypes';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { faHandHoldingDollar } from '@fortawesome/pro-light-svg-icons';
import { CategoryIds } from '../../categoryIds';

@Component({
    selector: 'app-product-benefits',
    imports: [FaIconComponent, CurrencyPipe, PercentPipe],
    templateUrl: './product-benefits.component.html',
    styleUrl: './product-benefits.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductBenefitsComponent {
  product = input.required<Schemas['Product']>();

  readonly faCheck = faCheck;
  readonly faTimes = faTimes;
  readonly priceIcon = faHandHoldingDollar;

  readonly isPlant = computed(() => {
    const plant = this.product();
    return plant.categoryIds?.some((c) => CategoryIds['Plant'] === c);
  });

  readonly priceOptions = computed(() => {
    const plant = this.product();

    if (plant.calculatedPrices.length === 0) {
      return [];
    }

    const basePrice = plant.calculatedPrice.unitPrice;

    const prices = plant.calculatedPrices
      .filter((p) => p.quantity > 1)
      .map((price) => {
        return {
          quantity: price.quantity,
          unitPrice: price.unitPrice,
          totalPrice: price.totalPrice,
          listPrice: price.listPrice,
          savings: 1 - price.unitPrice / basePrice,
        };
      });

    return prices;
  });
}
