import {
  ChangeDetectionStrategy,
  Component,
  input,
  model,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass } from '@angular/common';
import { faCaretRight } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'app-collapsable-option-group',
  imports: [FaIconComponent, NgClass],
  templateUrl: './collapsable-option-group.component.html',
  styleUrl: './collapsable-option-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsableOptionGroupComponent {
  label = input.required<string>();
  selected = input.required<string[]>();

  open = model<boolean>(false);
  collapsable = input<boolean>(false);
  showLabel = input<boolean>(false);

  toggle() {
    if (!this.collapsable()) {
      return;
    }

    this.open.update((s) => !s);
  }

  protected readonly faCaretRight = faCaretRight;
}
